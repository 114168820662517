import { config } from "../config";
import { HttpService } from "./HttpService";

export const ProductService = {
    getVariant,
    getProduct,
    getGarmentSize
}

const BASE_URL = '';

async function getVariant(productBCD, userToken, mirrorData) {
    // const product = await HttpService.get(`${BASE_URL}products/${productBCD}`, {retailer_code: mirrorData.retailerCode}, userToken)
    const variant = await HttpService.get(`${BASE_URL}variant/${productBCD}`, {retailer_code: mirrorData.retailerCode}, userToken)
    // const RATE = 0.93
    // console.log('product', product);
    // if (product.price) product.price = (product.price * RATE).toFixed(2);
    // if (product.similar_products) product.similar_products = product.similar_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    // if (product.completing_products) product.completing_products = product.completing_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    // console.log('product', product);
    return variant
}
async function getProduct(productBCD, userToken, mirrorData) {
    // const product = await HttpService.get(`${BASE_URL}products/${productBCD}`, {retailer_code: mirrorData.retailerCode}, userToken)
    const product = await HttpService.get(`${BASE_URL}/flsm/products/${productBCD}`, {retailer_code: mirrorData.retailerCode, sub_retailer_id: mirrorData.mirrorName}, userToken)
    // const RATE = 0.93
    // console.log('product', product);
    // if (product.price) product.price = (product.price * RATE).toFixed(2);
    // if (product.similar_products) product.similar_products = product.similar_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    // if (product.completing_products) product.completing_products = product.completing_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    // console.log('product', product);
    return product
}
async function getGarmentSize(measurements, userToken = null) {
    const garmentSize = await HttpService.post(`${config[process.env.REACT_APP_ENV_NAME].SERVERLESS_URL}`, null, measurements, userToken, true);
    return garmentSize
}