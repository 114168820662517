import { useEffect, useRef, useState } from 'react'
import { CircularProgress } from '../circularProgress/CircularProgress';
import { React360Viewer } from './Viewer'
// import { React360Viewer } from './Viewer/components/React360Viewer/React360Viewer'

export const AvatarCmp = ({ gender, bodyType, recommendedSize, sizesArray, inputSize, bgColor = '#e3e3e3', origin = 'widget', elSize = '608'}) => {
    const TOTAL_FRAMES = 29;
    const elRef = useRef()
    const [loadedImg, setLoadedImg] = useState([])   
    const [noImage, setNoImage] = useState(false)


    useEffect(() => {
      
        const imageFetchStatus = async () => {
         
            const res = await fetch(`https://360olga.s3.eu-west-1.amazonaws.com/${gender}/${bodyType}/${recommendedSize.toLowerCase()}/${recommendedSize.toLowerCase()}/1.png`);
            
            res.status !== 200 && setNoImage(true)
            
        }
        imageFetchStatus()
    }, [])

    
    useEffect(() => {
  
            window.MYSZ_AVATAR.loadedFrames += 1
            const res = spinnerPrecentage(TOTAL_FRAMES, loadedImg.length||1)
            window.MYSZ_AVATAR.precent = res 
            if (window.MYSZ_AVATAR.loadedFrames === TOTAL_FRAMES + 1) window.MYSZ_AVATAR.precent = 100;

    }, [loadedImg])

    useEffect(() => {
        (loadedImg.length||1) < TOTAL_FRAMES && setLoadedImg([])
    }, [recommendedSize, inputSize])


    
    const spinnerPrecentage= (totalFrames, currentFrame) => {
        return Number.parseInt(1 / totalFrames * 100 * (currentFrame===0?1:currentFrame))
    }


    if(noImage) return <div className="no-image">Avatar was not found</div>
    return (
        <div style={{ border: 'none', margin: '0 auto', backgroundColor: bgColor }}>
            <div className={`spinner ${(loadedImg.length||1) < TOTAL_FRAMES ? 'show' : 'hide'}`} >
                <CircularProgress precent={window.MYSZ_AVATAR.precent>100 ? 100 : window.MYSZ_AVATAR.precent} />
            </div>
            <section id="threesixty-container" className="container-fluid" style={{ visibility: (loadedImg.length||1) < TOTAL_FRAMES-1  ? 'hidden' : 'visible' }}>
                <div ref={elRef} className="threesixty product1 " style={{ margin: '0', '--size': elSize + 'px' }}>
                    <React360Viewer
                        imagesCount={TOTAL_FRAMES}
                        imagesBaseUrl={`https://360olga.s3.eu-west-1.amazonaws.com/${gender}/${bodyType}/${recommendedSize.toLowerCase()}/${recommendedSize.toLowerCase()}`}
                        imagesFiletype={`png`}
                        showRotationIconOnStartup={true}
                        setLoadedImg={setLoadedImg}
                    />
                    {/* <ThreeSixty
                        amount={29}
                        imagePath={`https://360olga.s3.eu-west-1.amazonaws.com/${gender}/${bodyType}/${recommendedSize.toLowerCase()}/${sizesArray[inputSize].toLowerCase()}`}
                        fileName='{index}.png'
                    /> */}
                </div>
            </section>
        </div>
    )
}