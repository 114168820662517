import { useEffect, useState } from "react"
import { Form1 } from "./Form1";
import { Form2 } from "./Form2";
import { Form3 } from "./Form3";
import { BAND_SIZES, BODY_SHAPES, BRA_UNIT, CUP_SIZES, GENDER, getObjKey, HEIGHT_UNIT, WEIGHT_UNIT } from "../helpers/helpers";

export const Form = ({ setData }) => {
    const [step, setStep] = useState(1);

    const [gender, setGender] = useState(GENDER.male)
    const [heightUnit, setHeightUnit] = useState(HEIGHT_UNIT.cm)
    const [height, setHeight] = useState('')
    const [heightInch, setHeightInch] = useState('0')
    const [weightUnit, setWeightUnit] = useState(WEIGHT_UNIT.kg)
    const [weight, setWeight] = useState('')

    const [knownBraSize, setKnowBraSize] = useState(false)
    const [braUnit, setBraUnit] = useState(0)
    const [bandSize, setBandSize] = useState({ value: BAND_SIZES[getObjKey(BRA_UNIT, braUnit)][0], label: BAND_SIZES[getObjKey(BRA_UNIT, braUnit)][0] })
    const [cupSize, setCupSize] = useState({ value: CUP_SIZES[getObjKey(BRA_UNIT, braUnit)][0], label: CUP_SIZES[getObjKey(BRA_UNIT, braUnit)][0] })

    const [bellyShape, setBellyShape] = useState(BODY_SHAPES['average'])
    const [hipShape, setHipShape] = useState(BODY_SHAPES['rounder'])

    useEffect(() => {
        if (step === 2 && gender === GENDER.male) setStep(3)
        if (step === 4) {
            setData({ gender, heightUnit, height, heightInch, weightUnit, weight, knownBraSize, braUnit, bandSize, cupSize, bellyShape, hipShape })
            setStep(1)
        }
    }, [step])

    return (
        <>
            {step === 1 && <Form1 setStep={setStep} heightInch={heightInch} height={height} weight={weight} weightUnit={weightUnit} heightUnit={heightUnit} gender={gender} setWeight={setWeight} setWeightUnit={setWeightUnit} setHeightInch={setHeightInch} setHeight={setHeight} setGender={setGender} setHeightUnit={setHeightUnit} />}
            {step === 2 && <Form2 setStep={setStep} cupSize={cupSize} bandSize={bandSize} braUnit={braUnit} knownBraSize={knownBraSize} setCupSize={setCupSize} setBandSize={setBandSize} setBraUnit={setBraUnit} setKnowBraSize={setKnowBraSize} />}
            {step === 3 && <Form3 setStep={setStep} gender={gender} bellyShape={bellyShape} setBellyShape={setBellyShape} hipShape={hipShape} setHipShape={setHipShape} />}
        </>
    )
}