import React, { useEffect, useState, useRef } from 'react';
// import GirlImg from '../assets/img/girlImg.png';
// import Logo from '../assets/img/logo.svg';
import Scanner from '../assets/img/scanner.svg';
import QRCode from '../assets/img/qrCode.svg';
import { girlImage, logo } from '../assets/img/staticImages';
import backArrow from '../assets/img/back-arrow.svg';
import mobileGirl from '../assets/img/mobile-girl.webp';
import formQrImage from '../assets/img/form-qr.png';

export const Step1 = ({showFormBarcode, setShowFormBarcode}) => {

    const [showAddHomeBtn, setShowAddHomeBtn] = useState(true);
    const deferredPrompt = useRef(null);

    useEffect(() => {
        setTimeout(()=>{
            document.documentElement.style.height = '';
        },10)

        const bihandler = (e) => {
            e.preventDefault();
            deferredPrompt.current = e;
        }

        window.addEventListener('beforeinstallprompt', bihandler);
        return () => window.removeEventListener('beforeinstallprompt', bihandler)
    },[])


    let view = null;
    if(showFormBarcode === "FORM_QR") {
        view = <React.Fragment>
            <div className="img">
                <img src={girlImage} alt="Girl" />
            </div>
            <div className="main form-qr">
                <img className="form-qr__logo" src={logo} alt="MYSIZE logo"/>
                <div className="img girl-image">
                    <img src={mobileGirl} alt="Girl" />
                </div>
                <div className="text">
                    <h1 className="form-first-text">The future of <br />shopping is here! </h1>
                    
                    <h1 className="form-second-text">Scan to find your perfect fit!</h1>
                </div>

                <div className="step1__form-qr-code">
                    <img src={formQrImage} alt="Form QR Code"/>
                </div>
                <div className='step1__next-button' onClick={() => setShowFormBarcode("FORM_PROFILE_SCAN")}>Got a profile? Start here</div>
            </div>
        </React.Fragment>
    }else if(showFormBarcode === "FORM_PROFILE_SCAN") {
        view =  <div className="main form-profile-scan">
                    <div>
                        <img src={logo} className="logo" alt="MYSIZE logo" />
                        <img className="step1__back-arrow" src={backArrow} alt="back arrow" onClick={() => setShowFormBarcode("FORM_QR")}/>
                    </div>
                    <div className="text">
                        <h1>Scan Your Size Profile</h1>
                    </div>

                    <div className="animation footer">
                        <img src={Scanner} alt="Scanner" />
                        <div className="qr-code">
                            <img src={QRCode} alt="QR Code" />
                            <span></span>
                        </div>
                    </div>
                </div>
    }
    if(!showFormBarcode){
        view = <React.Fragment>
                    <div className="img">
                <img src={girlImage} alt="Girl" />
            </div>
            <div className="main">
                <img className={"mysz--logo"} src={logo} alt="MYSIZE logo" />

                <div className="text">
                    <h1>Scan Your Size Profile</h1>
                </div>

                <div className="animation footer">
                    <img src={Scanner} alt="Scanner" />
                    <div className="qr-code">
                        <img src={QRCode} alt="QR Code" />
                        <span></span>
                    </div>
                </div>
            </div>
    </React.Fragment>
    } 


    // 
    // ADD HOME BUTTON 
    // 
    const handleAddHome = () => {
        setShowAddHomeBtn(false);
        // Show the prompt
        deferredPrompt.current.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.current.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt.current = null;
        });
    }

    return (
        <section className="step1">
            {view}
        </section>
    )
}