import { useEffect, useRef, useState } from "react";
// import Logo from '../assets/img/smallLogo.svg';
// import IconCheck from "../assets/img/icon-check.svg";
// import BackArrow from "../assets/img/back-arrow.svg";
import { BAND_SIZES, BRA_UNIT, CUP_SIZES, getObjKey } from "../helpers/helpers";
import Select from 'react-select';
import { backArrow, redV, smallLogo } from "../assets/img/staticImages";


const customStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: '#fff',
            backgroundColor: state.isFocused ? '#666' : 'transparent',
            textAlign: 'center',
            ':hover': {
                ...provided[':hover'],
                backgroundColor: '#666',
            }
        }
    },
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export const Form2 = ({ setStep, cupSize, bandSize, braUnit, knownBraSize, setCupSize, setBandSize, setBraUnit, setKnowBraSize }) => {

    const [bandOptions, setBandOptions] = useState()
    const [cupOptions, setCupOptions] = useState()

    const bandRef = useRef()
    const cupRef = useRef()

    useEffect(() => {
        setBandOptions(BAND_SIZES[getObjKey(BRA_UNIT, braUnit)].map(band_size => {
            return { value: band_size, label: band_size }
        }))
        setCupOptions(CUP_SIZES[getObjKey(BRA_UNIT, braUnit)].map(cup_size => {
            return { value: cup_size, label: cup_size }
        }))
        bandRef.current && setBandSize({ value: BAND_SIZES[getObjKey(BRA_UNIT, braUnit)][bandRef.current.idx], label: BAND_SIZES[getObjKey(BRA_UNIT, braUnit)][bandRef.current.idx] })
        cupRef.current && setCupSize({ value: CUP_SIZES[getObjKey(BRA_UNIT, braUnit)][cupRef.current.idx], label: CUP_SIZES[getObjKey(BRA_UNIT, braUnit)][cupRef.current.idx] })

    }, [braUnit])

    useEffect(() => {
        if (bandRef.current) {
            bandRef.current.idx = BAND_SIZES[getObjKey(BRA_UNIT, braUnit)].findIndex(v => v === bandRef.current.props.value)
        }
    }, [bandSize])

    useEffect(() => {
        if (cupRef.current) {
            cupRef.current.idx = CUP_SIZES[getObjKey(BRA_UNIT, braUnit)].findIndex(v => v === cupRef.current.props.value)
        }
    }, [cupSize])

    const handleForm = (ev) => {
        ev.preventDefault()
        setStep(3)
    }

    return (
        <section className='form2'>
            <header className='header flex-center'>
                <img src={smallLogo} alt='Logo' />
            </header>
            <div className='back' onClick={()=>setStep(1)}>
                <img className='back-arrow' src={backArrow} alt='back arrow' />
            </div>
            <form onSubmit={(ev) => ev.preventDefault()}>
                <h1 className="form-title">Do you know your bra size?</h1>
                <div className="bra-size-container">
                    <div className={`bra-size button not-sure flex-center  ${!knownBraSize ? 'active' : ''}`} onClick={() => setKnowBraSize(false)}>
                        <label name='not-sure' className={`not-sure`}>
                            <img className={!knownBraSize ? 'show' : 'hidden'} src={redV} alt={!knownBraSize ? 'checked' : 'not-checked'} />
                            Not Sure
                        </label>
                    </div>
                    <div className={`bra-size button yes flex-center ${knownBraSize ? 'active' : ''}`} onClick={() => setKnowBraSize(true)}>
                        <label name='yes' className={`yes`}>
                            <img className={knownBraSize ? 'show' : 'hidden'} src={redV} alt={knownBraSize ? 'checked' : 'not-checked'} />
                            Yes
                        </label>
                    </div>
                </div>
                {knownBraSize && <div className='input-container bra-size'>
                    <div className='row1'>
                        <label>Bra Size</label>
                        <div className="switch">
                            <button className={`left us ${braUnit === BRA_UNIT.us ? 'active' : ''}`} onClick={() => setBraUnit(BRA_UNIT.us)}>US</button>
                            <button className={`right uk ${braUnit === BRA_UNIT.uk ? 'active' : ''}`} onClick={() => setBraUnit(BRA_UNIT.uk)}>UK</button>
                        </div>
                    </div>
                    <div className={`row2`}>
                        <div className='dropdown flex-center'>
                            <Select
                                defaultValue={bandSize.value}
                                value={bandSize.value}
                                onChange={setBandSize}
                                options={bandOptions}
                                placeholder={bandSize.label}
                                styles={customStyles}
                                ref={bandRef}
                                className='select'
                            />
                        </div>
                        <div className='dropdown flex-center'>
                            <Select
                                defaultValue={cupSize.value}
                                value={cupSize.value}
                                onChange={setCupSize}
                                options={cupOptions}
                                placeholder={cupSize.label}
                                styles={customStyles}
                                ref={cupRef}
                                className='select'
                            />
                        </div>
                    </div>
                </div>}
                <button className='button next' onClick={handleForm}>Next</button>
            </form>
        </section >
    )
}