export const CircularProgress = ({precent=0}) => {
    return (
        <div className="circular-progress" style={{'--precent': precent}}>
            <div className="precent">
                <svg>
                    <circle cx="70" cy="70" r="70"></circle>
                    <circle cx="70" cy="70" r="70"></circle>
                </svg>
                <div className="number">
                    <h2 abbr={precent}><span>%</span></h2>
                </div>
            </div>
        </div>
    )
}