import { useEffect, useRef, useState } from "react"
import { PieTimer } from "../PieTimer"

export const ShoppingMsg = ({ topImg, msg, buttonText, buttonCB, timer, closeCB }) => {
    const timeoutTimer = useRef();
    const intervalTimer = useRef()
    const temp = useRef(timer)

    const [idleTimer, setIdleTimer] = useState(new Date(timer||1000).toISOString().slice(14,-5))

    useEffect(() => {
        if (msg.includes('<%timer%>')) {
            intervalTimer.current = setInterval(() => {
                let newTime = new Date(`1970-01-01 00:${new Date(temp.current).toISOString().slice(14,-5)}`) - new Date('1970-01-01 00:00:01')
                if (newTime < 1000) {
                    clearInterval(intervalTimer.current)
                    // closeCB()
                }
                setIdleTimer(new Date(newTime).toISOString().slice(14,-5))
                temp.current = temp.current - 1000
            }, 1000)
        }

        return ()=>{
            clearTimeout(timeoutTimer.current)
            clearInterval(intervalTimer.current)
        }
    }, [])

    useEffect(() => {
        closeCB && (timeoutTimer.current = setTimeout(() => { closeCB() }, timer))
    }, [timer])

    return (
        <section className='shopping-msg'>
            <div className='screen' />
            {timer && closeCB && <PieTimer duration={timer} />}
            {topImg && <img className='img' src={topImg} alt='' />}
            <h1 className='msg'>{
                msg.includes('<%timer%>') ?
                    <><i>{msg.replace('<%timer%>', idleTimer)}</i></>
                    : msg
            }</h1>
            {buttonText && buttonCB && <button className='btn' onClick={buttonCB}>{buttonText}</button>}
        </section>
    )
}