import { config } from "../config";

export const gtagCustomEvent = (eventName, value = null) => {
    window.dataLayer.push({ 'event': eventName, value });
}

export const setFLSMstate = (stateName, value) => {
    if (!stateName || !value) return
    const tempName = _getTempName()
    if (!((tempName || config.FLSM_STATE_KEY) in window)) {
        clearFLSMstate()
    }
    window[tempName || config.FLSM_STATE_KEY][stateName] = value
    window.dataLayer.push({flsm_data:{user_id: window[tempName || config.FLSM_STATE_KEY]?.USER?.id, 
    retailer_code: window[tempName || config.FLSM_STATE_KEY]?.RETAILER_DATA?.retailerCode,
    product_id: window[tempName || config.FLSM_STATE_KEY]?.PRODUCT?.id,
    variant_id: window[tempName || config.FLSM_STATE_KEY]?.VARIANT?.id,
    mirror_name: window[tempName || config.FLSM_STATE_KEY]?.RETAILER_DATA?.mirrorName}})
}

export const clearFLSMstate = (tempName = _getTempName()) => {
    // console.log(tempName, !('FLSM_STATE_KEY' in config))
    window[tempName || config.FLSM_STATE_KEY] = ((tempName || config.FLSM_STATE_KEY) in window && 'RETAILER_DATA' in window[tempName || config.FLSM_STATE_KEY]) ? { RETAILER_DATA: window[tempName || config.FLSM_STATE_KEY].RETAILER_DATA } : {}
}

const _getTempName = () => {
    let tempName = null;
    if (!('FLSM_STATE_KEY' in config)) tempName = 'FLSM_STATE'
    return tempName
}