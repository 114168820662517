import { useEffect, useRef, useState } from "react"
// import FullBackArrow from '../../assets/img/full-back-arrow.svg'
// import Creditcard from '../../assets/img/creditcard.svg'
// import BackArrow from '../../assets/img/back-arrow.svg'
// import Heart from '../../assets/img/heart.svg'
import { ItemList } from "./itemList"
import { ShoppingMsg } from "./shoppingMsg"
import { creditCard, fullBackArrow, heart } from "../../assets/img/staticImages"

export const Checkout = ({ shoppingBag, setShoppingBag, setCheckout, setShowShoppingBag, setStep }) => {
    const STATES = { initial: 0, processing: 1, complete: 2 }
    const [state, setState] = useState(STATES.initial)

    const detailsRef = useRef()
    const timer = useRef()

    useEffect(() => {
        if (state === STATES.processing) {
            timer.current = setTimeout(() => {
                setState(STATES.complete)
            }, 3000)
        }
        return () => {
            clearTimeout(timer.current)
        }
    }, [state])

    const shoppingBagQuantity = () => {
        let quantity = 0
        if (shoppingBag.length > 0) {
            shoppingBag.forEach(item => {
                quantity = +quantity + +item.quantity
            })
        }
        return quantity
    }

    const onCheckout = () => {
        setShowShoppingBag(false)
        setCheckout(false)
        setShoppingBag([])
        setStep(1)
    }

    return (state === STATES.complete ?
        <ShoppingMsg topImg={heart} msg={`Thank you for shopping with us!`} timer={3000} closeCB={onCheckout} />
        :
        <section className='checkout'>
            <div className='screen'></div>
            <div className='scroll'>
                <nav>
                    <div className='back' onClick={() => { clearTimeout(timer.current); state === STATES.initial ? setCheckout(false) : setState(state - 1) }}>
                        <img src={fullBackArrow} alt='back' />
                    </div>
                    <h1>Checkout</h1>
                </nav>
                <hr />
                <details ref={detailsRef}>
                    <summary><label>Your Bag</label><span>{`${shoppingBagQuantity()} Item${shoppingBagQuantity() > 1 ? 's' : ''}`}</span></summary>
                    <ItemList itemList={shoppingBag} />

                </details>
                <hr />
                <div className='state'>
                    <img src={creditCard} alt='swipe your card' />
                    {state === STATES.initial && <h1>Insert / swipe your card<br />to complete payment</h1>}
                    {state === STATES.processing && <h1>Processing...</h1>}
                </div>
                <footer>
                    {state === STATES.initial && <button className='btn proceed' onClick={() => { detailsRef.current.removeAttribute('open'); setState(STATES.initial + 1) }}>Proceed</button>}
                </footer>
            </div>
        </section>
    )
}