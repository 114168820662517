const baseConfig = {
    // PRODUCT_URL: process.env.REACT_APP_ENV_NAME==='production' ? 'https://flsm-be.herokuapp.com/api/v1/' : 'https://flsm-be-staging.herokuapp.com/api/v1/',
    // USER_URL: process.env.REACT_APP_ENV_NAME==='production' ? 'https://api.mysz.io/api/v1/' : 'https://api-staging.mysz.io/api/v1/',
    VERSION: "1.6",
    PREFIX: 'MYSIZE-',
    isShowForm: process.env.REACT_APP_IS_FORM_SHOWN,
    FLSM_STATE_KEY: 'FLSM_STATE',
    imageLoaderDelay: 500,
    SHOW_FORM_QR: localStorage.getItem("SHOW_FORM_QR") === "true",
    isScannerVisible: process.env.REACT_APP_INSTORE === 'true',
    dev:{
        API_KEY: 'AIzaSyAAu3emEhazek1O54_7jrwv16Q7jLqCBeg',
        AUTH_DOMAIN: 'mysizeid-retailers-staging.firebaseapp.com',
        DATABASE_URL: 'https://mysizeid-retailers-staging.firebaseio.com',
        PROJECT_ID: 'mysizeid-retailers-staging',
        STORAGE_BUCKET: 'mysizeid-retailers-staging.appspot.com',
        MESSAGING_SENDER_ID: 651206021906,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        // USER_URL: "https://api-staging.mysz.io/api/v1/",
        PRODUCT_URL: 'https://api-staging.mysz.io/api/v1/flsm/',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        REACT_APP_RETAILER_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNjYmM4ZjIyMDJmNjZkMWIxZTEwMTY1OTFhZTIxNTZiZTM5NWM2ZDciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXVkIjoibXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXV0aF90aW1lIjoxNjA4NjI1MTUwLCJ1c2VyX2lkIjoiUFMxWTU5Vm5SRGVJcEpWaGV3NXZ3eHNIUFJtMSIsInN1YiI6IlBTMVk1OVZuUkRlSXBKVmhldzV2d3hzSFBSbTEiLCJpYXQiOjE2MDg2MjUxNTAsImV4cCI6MTYwODYyODc1MCwiZW1haWwiOiJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.WzwNNM3hzEZLTYVlBQdc31VCn2fTdZ6eUaF7vfO4-PvgwJaxXwjT9tHWxhaBstJR8BpZ1T1m3JixdbAWOLbBOtFeDlmxjXUrx9sCtD-7JbP9dtFRkckdDMgO43pB6aK5XnWl6sr-e2fysGB8aKNh8pz0GnUeshkJxn-6sbJg-1VR1g9WX0zLIuImkAbBnCVCFCizBsIHKY2lUSIhPL24nlELcwllWw4gL1LhMic4i1wgOEoeHcfIKzZpyNzJGZH75XMTbbxh5WrYqWO1Tw0TCt0nzee6LTiQ6heZilRscVfqYrs78GTwmJre7WHLp2YDNSK3Q9z_PMs6fRMu47hYqQ",
        SERVERLESS_URL: "https://api2-staging.mysz.io/api/v2/widget/garment_size"
    },
    local:{
        API_KEY: 'AIzaSyAAu3emEhazek1O54_7jrwv16Q7jLqCBeg',
        AUTH_DOMAIN: 'mysizeid-retailers-staging.firebaseapp.com',
        DATABASE_URL: 'https://mysizeid-retailers-staging.firebaseio.com',
        PROJECT_ID: 'mysizeid-retailers-staging',
        STORAGE_BUCKET: 'mysizeid-retailers-staging.appspot.com',
        MESSAGING_SENDER_ID: 651206021906,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        // USER_URL: "https://api-staging.mysz.io/api/v1/",
        PRODUCT_URL: 'http://localhost:5000/api/v1/flsm/',
        BACKEND_URL: 'http://localhost:5000/api/v1',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        REACT_APP_RETAILER_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNjYmM4ZjIyMDJmNjZkMWIxZTEwMTY1OTFhZTIxNTZiZTM5NWM2ZDciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXVkIjoibXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXV0aF90aW1lIjoxNjA4NjI1MTUwLCJ1c2VyX2lkIjoiUFMxWTU5Vm5SRGVJcEpWaGV3NXZ3eHNIUFJtMSIsInN1YiI6IlBTMVk1OVZuUkRlSXBKVmhldzV2d3hzSFBSbTEiLCJpYXQiOjE2MDg2MjUxNTAsImV4cCI6MTYwODYyODc1MCwiZW1haWwiOiJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.WzwNNM3hzEZLTYVlBQdc31VCn2fTdZ6eUaF7vfO4-PvgwJaxXwjT9tHWxhaBstJR8BpZ1T1m3JixdbAWOLbBOtFeDlmxjXUrx9sCtD-7JbP9dtFRkckdDMgO43pB6aK5XnWl6sr-e2fysGB8aKNh8pz0GnUeshkJxn-6sbJg-1VR1g9WX0zLIuImkAbBnCVCFCizBsIHKY2lUSIhPL24nlELcwllWw4gL1LhMic4i1wgOEoeHcfIKzZpyNzJGZH75XMTbbxh5WrYqWO1Tw0TCt0nzee6LTiQ6heZilRscVfqYrs78GTwmJre7WHLp2YDNSK3Q9z_PMs6fRMu47hYqQ",
        SERVERLESS_URL: "https://api2-staging.mysz.io/api/v2/widget/garment_size"
    },
    staging:{
        API_KEY: 'AIzaSyAAu3emEhazek1O54_7jrwv16Q7jLqCBeg',
        AUTH_DOMAIN: 'mysizeid-retailers-staging.firebaseapp.com',
        DATABASE_URL: 'https://mysizeid-retailers-staging.firebaseio.com',
        PROJECT_ID: 'mysizeid-retailers-staging',
        STORAGE_BUCKET: 'mysizeid-retailers-staging.appspot.com',
        MESSAGING_SENDER_ID: 651206021906,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        // USER_URL: "https://api-staging.mysz.io/api/v1/",
        PRODUCT_URL: 'https://api-staging.mysz.io/api/v1/flsm/',
        REACT_APP_RETAILER_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNjYmM4ZjIyMDJmNjZkMWIxZTEwMTY1OTFhZTIxNTZiZTM5NWM2ZDciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXVkIjoibXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUtc3RnIiwiYXV0aF90aW1lIjoxNjA4NjI1MTUwLCJ1c2VyX2lkIjoiUFMxWTU5Vm5SRGVJcEpWaGV3NXZ3eHNIUFJtMSIsInN1YiI6IlBTMVk1OVZuUkRlSXBKVmhldzV2d3hzSFBSbTEiLCJpYXQiOjE2MDg2MjUxNTAsImV4cCI6MTYwODYyODc1MCwiZW1haWwiOiJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhbG9uLXRlc3Qtb3JkZXJzLm15c2hvcGlmeS5jb21AbXlzei5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.WzwNNM3hzEZLTYVlBQdc31VCn2fTdZ6eUaF7vfO4-PvgwJaxXwjT9tHWxhaBstJR8BpZ1T1m3JixdbAWOLbBOtFeDlmxjXUrx9sCtD-7JbP9dtFRkckdDMgO43pB6aK5XnWl6sr-e2fysGB8aKNh8pz0GnUeshkJxn-6sbJg-1VR1g9WX0zLIuImkAbBnCVCFCizBsIHKY2lUSIhPL24nlELcwllWw4gL1LhMic4i1wgOEoeHcfIKzZpyNzJGZH75XMTbbxh5WrYqWO1Tw0TCt0nzee6LTiQ6heZilRscVfqYrs78GTwmJre7WHLp2YDNSK3Q9z_PMs6fRMu47hYqQ",
        SERVERLESS_URL: "https://api2-staging.mysz.io/api/v2/widget/garment_size",
        BACKEND_URL: "https://api-staging.mysz.io/api/v1"
    },
    production:{
        API_KEY: "AIzaSyAteZy_YZ36ybvro-fA5sOhpnHPnP4IDMo",
        AUTH_DOMAIN: "auth.mysz.io",
        DATABASE_URL: "https://mysizeid-retailers.firebaseio.com",
        PROJECT_ID: "mysizeid-retailers",
        STORAGE_BUCKET: "mysizeid-retailers.appspot.com",
        MESSAGING_SENDER_ID: 298488817318,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        // USER_URL: 'https://api.mysz.io/api/v1/',
        PRODUCT_URL: 'https://api.mysz.io/api/v1/flsm/',
        REACT_APP_RETAILER_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUiLCJhdWQiOiJteXNpemVpZC1yZXRhaWxlcnMtd2Vic2l0ZSIsImF1dGhfdGltZSI6MTYxMDI3NjQ3NCwidXNlcl9pZCI6IlZvZHRUMzUyZmloMGxDc1FwTEFvUEw1TkljSTIiLCJzdWIiOiJWb2R0VDM1MmZpaDBsQ3NRcExBb1BMNU5JY0kyIiwiaWF0IjoxNjEwMjc2NDc0LCJleHAiOjE2MTAyODAwNzQsImVtYWlsIjoiZ2tAbXlzaXplLmdrIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImdrQG15c2l6ZS5nayJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.fLIrm9ZTrHuM1KZZ6KvvR9do2XloNEFoYYm4mZ1jojtmb_tn3mk2CJxckfg0mw5NFC2TisZ7fwkeDP0-3kjY-b4hrjrYC1JgfPqvBDHGMohmSbXmtab4BnSc7niZ0ofmLwAOpE6Xd9X_W8XRgU3SL2Hr-BLr5cfypIjpqEJj4CkxzTEhHJ7qdQZcNvC88W4klCRLqh0jefdqmLtBMa3QmPZmAWV4QN8Af3vLo1AqvHSS1jAhFbL_JsrI6vfhubHrPTeJVgcBa723EI7rYVkTKHVwR35iUSKdVSCrcJF5UieqCKbL_dVEsUgt_5Dt_oT54aWsumoD1b_D2vFkyxPuzg",
        SERVERLESS_URL: "https://api2.mysz.io/api/v2/widget/garment_size",
        BACKEND_URL: "https://api.mysz.io/api/v1"
    },
}

process.env.REACT_APP_INSTORE && (baseConfig.instoreDev = {...baseConfig.dev, isInstoreDev: true})

export const config = {...baseConfig}