import { useEffect, useState } from "react";
import { GENDER, HEIGHT_UNIT, WEIGHT_UNIT } from "../helpers/helpers";
// import IconCheck from "../assets/img/icon-check.svg";
// import Logo from "../assets/img/logo.svg";
// import RangeSlider from 'react-bootstrap-range-slider';
import { logo, redV } from "../assets/img/staticImages";

export const Form1 = ({ setStep, weightUnit, heightUnit, gender, setWeight, setWeightUnit, setHeightInch, setHeight, setGender, setHeightUnit, weight, height, heightInch }) => {
    const [heightFocus, setHeightFocus] = useState(false)
    const [errors, setErrors] = useState({ height: false, weight: false })

    useEffect(() => {
        setWeightUnit(heightUnit === HEIGHT_UNIT.cm ? WEIGHT_UNIT.kg : WEIGHT_UNIT.lb)
        setErrors({ height: false, weight: false })
    }, [heightUnit])

    useEffect(() => {
        setHeightUnit(weightUnit === WEIGHT_UNIT.kg ? HEIGHT_UNIT.cm : HEIGHT_UNIT.in)
    }, [weightUnit])

    useEffect(() => {
        if (height.length > 3) {
            setHeight(height.slice(0, 3))
            return
        }
        if (heightInch.length > 3 && heightUnit === HEIGHT_UNIT.in) {
            setHeightInch(heightInch.slice(0, 3))
            return
        }
        if (weight.length > 3) {
            setWeight(weight.slice(0, 3))
            return
        }
        const error = { height: false, weight: false }
        if (height > 400 || (heightInch > 400 && heightUnit === HEIGHT_UNIT.in)) {
            error.height = true
        }
        if (weight > 400) {
            error.weight = true
        }
        error.height = height === '' ? false : error.height
        error.weight = weight === '' ? false : error.weight
        if (errors.height !== error.height || errors.weight !== error.weight) setErrors(error)
    }, [height, weight, heightInch, errors])

    const metricToImperial = (height, weight) => {
        const inches = height * 0.393701;
        const newFeet = Math.floor(inches / 12);
        const newInches = Math.round(inches - newFeet * 12)
        const newWeight = Math.round(weight * 2.20462)
        setHeight(newFeet)
        setHeightInch(newInches)
        setWeight(newWeight)
    }

    const imperialToMetric = (feet, inches, weight) => {
        setHeight(Math.round(feet * 30.48 + inches * 2.45))
        setWeight(Math.round(weight * 0.453592))
    }

    const handleForm = (ev) => {
        ev.preventDefault()
        if (isNaN(parseInt(height)) || parseInt(height) < 5 || parseInt(height) > 400 || isNaN(parseInt(heightInch)) || parseInt(heightInch) > 400 || isNaN(parseInt(weight)) || parseInt(weight) < 5 || parseInt(weight) > 400) {
            setErrors({ height: isNaN(parseInt(height)) || parseInt(height) < 5 || parseInt(height) > 400 || isNaN(parseInt(heightInch)) || parseInt(heightInch) < 5 || parseInt(heightInch) > 400, weight: isNaN(parseInt(weight)) || parseInt(weight) < 5 || parseInt(weight) > 400 })
            return
        }
        alert()
        setStep(2)
    }

    return (
        <section className="form1">
            <header className='header flex-center'>
                <img src={logo} alt='Logo' />
            </header>
            <form onSubmit={handleForm}>
                <h1 className="form-title">Get The Right Size<br />Shop With Ease</h1>
                <div className="gender-container">
                    <div className={`gender button male flex-center  ${gender === GENDER['male'] ? 'active' : ''}`} onClick={() => setGender(GENDER['male'])}>
                        <label name='male' className={`male`}>
                            <img className={gender === GENDER['male'] ? 'show' : 'hidden'} src={redV} alt={gender === GENDER['male'] ? 'checked' : 'not-checked'} />
                            Male
                        </label>
                    </div>
                    <div className={`gender button female flex-center ${gender === GENDER['female'] ? 'active' : ''}`} onClick={() => setGender(GENDER['female'])}>
                        <label name='female' className={`female`}>
                            <img className={gender === GENDER['female'] ? 'show' : 'hidden'} src={redV} alt={gender === GENDER['female'] ? 'checked' : 'not-checked'} />
                            Female
                        </label>
                    </div>
                </div>
                <div className='input-container height'>
                    <div className='row1'>
                        <label>Height</label>
                        <div className="switch">
                            <button className={`left cm ${heightUnit === HEIGHT_UNIT.cm ? 'active' : ''}`} onClick={() => {
                                if (heightUnit === HEIGHT_UNIT.in) {
                                    setHeightUnit(HEIGHT_UNIT.cm)
                                    imperialToMetric(height, heightInch, weight)
                                }
                            }}>cm</button>
                            <button className={`right in ${heightUnit === HEIGHT_UNIT.in ? 'active' : ''}`} onClick={() => {
                                if (heightUnit === HEIGHT_UNIT.cm) {
                                    setHeightUnit(HEIGHT_UNIT.in)
                                    metricToImperial(height, weight)
                                }
                            }}>in</button>
                        </div>
                    </div>
                    <div className={`row2 ${heightFocus ? 'focus' : ''} ${errors.height ? 'invalid' : ''}`}>
                        <div>
                            <input autoFocus tabIndex={1} type='number' className={`${heightFocus ? 'focus' : ''} ${heightUnit === HEIGHT_UNIT.cm ? 'cm' : 'ft'}`} max={999} value={height} onChange={({ target }) => setHeight(target.value)} onFocus={(ev) => { setHeightFocus(true); ev.target.select() }} onBlur={() => setHeightFocus(false)} />
                            {/* <RangeSlider
                                className={`${heightFocus ? 'focus' : ''} ${heightUnit === HEIGHT_UNIT.cm ? 'cm' : 'ft'}`}
                                max={400}
                                min={0}
                                step={1}
                                tooltip={'on'} //auto or on or off
                                tooltipPlacement={'top'} // top or bottom
                                tooltipLabel={value => `${value}cm`}
                                tooltipProps={{ class: 'tooltip' }}
                                value={height}
                                onChange={({ target }) => setHeight(target.value)}
                            /> */}
                            <span className='input-label'>{heightUnit === HEIGHT_UNIT.cm ? '' : 'ft.'}</span>
                        </div>
                        {heightUnit === HEIGHT_UNIT.in && <div>
                            <input tabIndex={2} type='number' className={`${heightFocus ? 'focus' : ''} ${heightUnit === HEIGHT_UNIT.in ? 'in' : 'hide'}`} max={999} value={heightInch} onChange={({ target }) => setHeightInch(target.value)} onFocus={(ev) => { setHeightFocus(true); ev.target.select() }} onBlur={() => setHeightFocus(false)} />
                            <span className='input-label'>in.</span>
                        </div>}
                    </div>
                    <div className={`row3 errors height ${errors.height ? 'show' : 'hide'}`}>
                        <label className='error'>Please enter height</label>
                    </div>
                </div>
                <div className='input-container weight'>
                    <div className='row1'>
                        <label>Weight</label>
                        <div className="switch">
                            <button className={`left kg ${weightUnit === WEIGHT_UNIT.kg ? 'active' : ''}`} onClick={() => {
                                if (weightUnit === WEIGHT_UNIT.lb) {
                                    setWeightUnit(WEIGHT_UNIT.kg)
                                    imperialToMetric(height, heightInch, weight)
                                }
                            }}>kg</button>
                            <button className={`right lb ${weightUnit === WEIGHT_UNIT.lb ? 'active' : ''}`} onClick={() => {
                                if (weightUnit === WEIGHT_UNIT.kg) {
                                    setWeightUnit(WEIGHT_UNIT.lb)
                                    metricToImperial(height, weight)
                                }
                            }}>lb</button>
                        </div>
                    </div>
                    <div className={`row2 ${errors.weight ? 'invalid' : ''}`}>
                        <div>
                            <input tabIndex={3} type='number' className={weightUnit === WEIGHT_UNIT.kg ? 'kg' : 'lb'} max={999} value={weight} onChange={({ target }) => setWeight(target.value)} onFocus={(ev) => ev.target.select()} />
                            {/* {weightUnit === WEIGHT_UNIT.lb && <input type='number' className={weightUnit === WEIGHT_UNIT.lb ? 'lb' : 'hide'} onChange={({ target }) => setWeightLbs(target.value)} />} */}
                        </div>
                    </div>
                    <div className={`row3 errors height ${errors.weight ? 'show' : 'hide'}`}>
                        <label className='error'>Out of range</label>
                    </div>
                </div>
                <button className='button next' type='submit' onClick={handleForm}>Next</button>
                <footer className='footer'>By proceeding, you agree to MySizeID’s <a href="#">TOS</a> and <a href="#">Privacy Policy</a></footer>
            </form>
        </section>
    )
}