export const NavMsg = ({onCancel, onOk, isShow}) => {

    return (
        <section className={`nav-msg ${isShow ? `show` : ''}`}>
            <h2>Are you sure you want<br />to restart the session?</h2>
            <div className="buttons">
                <button onClick={onOk}>Yes</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
        </section>
    )
}