// change view port with 'zoom' (zoom: 0.15), and color with 'color' (color: yellow)

export const Loader = (asText = false) => {
    return (
        asText ? 
        `<svg style="position:relative;width:150px;height:150px"> <circle cx="70" cy="70" r="70" style="stroke-dashoffset: 0;stroke: none;width: 150px;height: 150px;fill: none;stroke-width: 10; transform: translate(5px,5px); stroke-dasharray: 440; stroke-linecap: round"></circle><circle cx="70" cy="70" r="70" style="stroke-dashoffset: 100;stroke: currentColor; width: 150px;height: 150px;fill: none; stroke-width: 10; transform: translate(5px,5px); stroke-dasharray: 440; stroke-linecap: round"></circle></svg >`
        :
        <svg style={{
            position: 'relative',
            width: '150px',
            height: '150px'
        }}>
            <circle cx="70" cy="70" r="70" style={{
                'stroke-dashoffset': '0',
                stroke: 'none',
                width: '150px',
                height: '150px',
                fill: 'none',
                'stroke-width': '10',
                transform: 'translate(5px,5px)',
                'stroke-dasharray': '440',
                'stroke-linecap': 'round'
            }}></circle>
            <circle cx="70" cy="70" r="70" style={{
                'stroke-dashoffset': '100',
                stroke: 'currentColor',
                width: '150px',
                height: '150px',
                fill: 'none',
                'stroke-width': '10',
                transform: 'translate(5px,5px)',
                'stroke-dasharray': '440',
                'stroke-linecap': 'round'
            }}>

            </circle>
        </svg >
    )
}