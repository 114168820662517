// import CloseIcon from '../../assets/img/close-icon-white.svg';
import { closeIconWhite } from '../../assets/img/staticImages';
// import { useEffect, useRef, useState } from 'react';
import { ItemList } from './itemList';

export const ShoppingBag = ({ shoppingBag, setShoppingBag, setShowShoppingBag, setCheckout }) => {

    const removeItem = (itemToRemove) => {
        let newShoppingBag = [...shoppingBag]
        const idx = newShoppingBag.findIndex(item => item === itemToRemove)
        newShoppingBag[idx].quantity--;
        newShoppingBag = newShoppingBag.filter(currItem => currItem.quantity > 0)
        setShoppingBag(newShoppingBag)
    }

    return (
        <section className='shopping-bag'>
            <div className='container'>
                <img className='close' src={closeIconWhite} alt='close shopping bag' onClick={() => setShowShoppingBag(false)} />
                <h1 className='title'>Shopping Bag</h1>
                <hr />
                <ItemList itemList={shoppingBag} removeItem={removeItem} />
                <hr />
                {shoppingBag.length>0 && <button className='btn checkout' onClick={() => setCheckout(true)}>Checkout</button>}
            </div>
        </section>
    )
}