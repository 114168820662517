import { useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { context } from '../../App';
// import Logo from '../../assets/images/size-new-logo.png';
import "./ScannerIframe.scss"
import backArrow from '../../assets/img/arrow.svg';


const navigate = (where)=>{

}
export const ScannerIframe = ({setScannerVisible, setIsFreshSession}) => {
    // const userContext = useContext(context);
    // const userToken = userContext.data?.user?.token;
    // let navigate = useNavigate();

    useEffect(() => {
        console.log('adding event listner');
        // if (!userToken) 
        //     navigate('/');
        window.addEventListener("message", (event) => {
            const action = event.data.action
            switch (action) {
                case 'back':
                    // userContext.action='back';
                    // navigate(userContext.origin);
                    break;
                case 'close':
                    break;
                default:
                    // no action ===>>> barcode is scanned
                    const barcode = event.data.barCode;
                    if (barcode) {
                        console.log(barcode);
                        // alert("COOL",barcode);
                        window.scannerOpen=false;
                        // userContext.barcode = { data: barcode };
                        setScannerVisible(false);
                        window.SCAN(barcode);
                        navigate('/result');
                    }
                    break;
            }
        }, false)
        return () => {
            console.log('removing event listner');
            window.removeEventListener("message", (event) => { })
        }
    }, [])

    return (
        <section className="scanner-iframe">
            {/* <img className="logo" src={Logo} alt="Logo"/> */}
            <iframe
                id="scannerIframe"
                src="./ScannerIframe.html"
                title="ScannerIframe"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="camera *"
            // referrerPolicy="strict-origin"
            // seamless
            >
            </iframe>
            {/* <p>
                Scan an item's barcode to get your<br/>recommended size.
            </p>
            <button onClick={()=>{
                userContext.action='back';
                navigate(userContext.origin);
            }}>CANCEL</button> */}
            {/* <button onClick={()=>{
                setScannerVisible(false);
                setIsFreshSession(false);
            }}>back</button> */}
            <img src={backArrow} alt='back arrow' onClick={() => {
                setScannerVisible(false);
                setIsFreshSession(false);
            }}></img>
        </section>
    )
}