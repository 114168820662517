import { config } from "../config";
import { GENDER, HEIGHT_UNIT } from "../helpers/helpers";
import FirebaseService from "./FirebaseService";
import { HttpService } from "./HttpService";


const BASE_URL = '';

const getUser = async (profileBCD) => {
    const retailerToken = config[process.env.REACT_APP_ENV_NAME].REACT_APP_RETAILER_TOKEN;
    let user = await HttpService.get(`/${BASE_URL}/gk/users/me`, { barcode: profileBCD }, retailerToken)
    if(!user) throw Error("Somthing went wrong...")
    const body_type = await HttpService.get(`${BASE_URL}/users/me/body_type`, null, user.token);
    user.bodyType = Number(body_type.body_type);
    return user
}


const getGuestUser = async (measurementSystem, gender, email) => {
    const REQUEST_URL = BASE_URL + "users/widget"

    // create an anonymous user in firebase
    const firebaseUser = await new FirebaseService().signUpGuestUser();
    const body = {
        measurement_system: measurementSystem === HEIGHT_UNIT.cm ? 'metric' : 'imperial',
        gender: gender === GENDER.male ? 'male' : 'female',
        is_guest: true,
        auth_token: firebaseUser.token,
        ...(email ? { email } : {})
    }

    let response = null;
    try {
        response = await HttpService.post(REQUEST_URL, null, body, firebaseUser.token);
        response.token = firebaseUser.token
    } catch (error) {
        return error
    }
    return response;
}

// used for updating a guest user after creation
const updateGuestUser = async (auth_token, measurementSystem, gender, height, weight) => {
    const REQUEST_URL = BASE_URL + "users/widget/me/measurements"

    const body = {
        measurement_system: measurementSystem === HEIGHT_UNIT.cm ? 'metric' : 'imperial',
        gender: gender === GENDER.male ? 'male' : 'female',
        isGuest: true,
        auth_token: auth_token,
        height_value: height,
        weight_value: weight
    }

    let response = null;
    try {
        response = await HttpService.post(REQUEST_URL, null, body, auth_token);
        // console.log(response);
    } catch (error) {
        console.log(error);
        return error
    }
    return response;
}

const getUserBarcode = async (userToken) => {
    const REQUEST_URL = BASE_URL + "users/me"
    const user = await HttpService.get(REQUEST_URL,null,userToken)
    return user.profile_barcode
}

function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const UserService = {
    getUser,
    getGuestUser,
    updateGuestUser,
    getUserBarcode,
    parseJwt,
}
