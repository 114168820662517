import { tShirt } from '../assets/img/staticImages';
// import tshirt from '../assets/img/tshirt.svg';

export const BringmeMsg = ({onClose, isShow}, children) => {

    return (
        <section className={`bringme-msg ${isShow ? `show` : ''}`}>
            <img src={tShirt} alt="shopping bag" />
            <h1>We're On It!</h1>
            <h2>Our staff will bring you the<br />requested item shortly.</h2>
        </section>
    )
}