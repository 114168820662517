import { useEffect, useRef } from "react"

export const SliderMsg = ({ img, icon, msg, timer = 2000, isShow = false, timerCB }) => {
    const ref = useRef()
    const timeoutTimer = useRef()

    useEffect(() => {

        return ()=>{
            clearTimeout(timeoutTimer.current)
        }
    }, [])

    useEffect(() => {
        if (isShow) {
            clearTimeout(timeoutTimer.current)
            timeoutTimer.current = setTimeout(() => {
                ref.current.classList.remove('show')
                ref.current.classList.add('hide')
                timerCB()
            }, timer)
        }
    }, [isShow])

    return (
        <section ref={ref} className={`slider-msg ${isShow ? 'show' : 'hide'}`}>
            {img && <img className='img' src={img} alt='' />}
            {icon && <img className='icon' src={icon} alt='' />}
            {msg && <label className='msg'>{msg}</label>}
        </section>
    )
}