// import React from 'react'
// import { ProductService } from '../services/ProductService';
// import  {Step3}  from './Step3';
// import TagIcon from '../assets/img/tagIcon.svg';
import ProgressiveImage from "react-progressive-graceful-image";
import { config } from '../config';
import { tagIcon } from '../assets/img/staticImages';




export default function Product({ product, user, setScannedItem, currency }) {

  const getProduct = async () => {
    setScannedItem({ scanned: product?.main_variant?.barcode })
  }

  const fixedName = (name) => {
    let lowerCase = name.toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
  }


  const prod = (<div className="product" key={product.id}>
    <div className="product__image">
      {/* <img src={product?.main_variant?.image?.url ? product?.main_variant?.image?.url : TagIcon } alt="prod"/> */}
      <ProgressiveImage delay={config.imageLoaderDelay} src={product?.main_variant?.thumb ? product?.main_variant?.thumb : tagIcon } placeholder=''>
        {(src, loading) => loading ? <div className='shimmer-loader' /> : <img src={src} alt="Product" />}
      </ProgressiveImage>
    </div>
    <div className="product__name" >{fixedName(product.name)}</div>
    <div className="product__price">{`${product?.currency?.currency}${product?.price}`}</div>
  </div>
  )
  // string.charAt(0).toUpperCase() + string.slice(1)



  return (
    <div onClick={getProduct}>{prod}</div>
  )
}
