
var barcode = '';

function initScanner() {
    barcode = '';
}

function scan(key) {
    if (key === 'Enter') {
        return barcode
    }
    barcode += key
}


const getUserMeasurements = (scannedItem) => {
    console.log({scannedItem});
    const splittedMeasurements = scannedItem.split(',').map(s => +s);
    if(splittedMeasurements.length !== 10) throw new Error('Scanned wrong number of parameters');
    if(splittedMeasurements.filter(num => isNaN(num)).length > 0) throw new Error('One of parameter is not a number');
    const user = {
        measurements: {
            height: splittedMeasurements[0],
            weight: splittedMeasurements[1],
            belly_shape: splittedMeasurements[4],
            hip_shape: splittedMeasurements[5],
            bra_band: splittedMeasurements[6],
            bra_cup: splittedMeasurements[7],
            bra_unit: splittedMeasurements[8],
        },
        gender: splittedMeasurements[2] === 0 ? "male" : "female",
        measurement_system: splittedMeasurements[3] === 0 ? "metric" : "imperial",
        body_type: splittedMeasurements[9]
    }
    return user;
}


export {getUserMeasurements, scan, initScanner }
