import React, { useState, useEffect, useRef } from 'react';
// import closeIcon from '../assets/img/close-icon.svg';
import { closeIconBlack } from '../assets/img/staticImages';
import { SIZE_SORT_ORDER } from '../helpers/helpers';
import { AvatarCmp } from './AvatarCmp/AvatarCmp';
import SwitchButtonSmallCircle from './UI/SwitchButtonSmallCircle';

window.MYSZ_AVATAR = {loadedFrames: 0, precent: 0}

export default function Avatar({ gender, bodyType, recommendedSize, setShowAvatar }) {

  const [inputSize, setInputSize] = useState(null);
  const [sizesArray, setSizesArray] = useState([]);
  const [elSize, setElSize] = useState();
  const [fixedRecommendedSize, setFixedRecommendedSize] = useState();

  const avatarContainerRef = useRef()
  const panelRef = useRef()
  const switchRef = useRef()

  const sizesAllowed = {
    male: ['S', 'M', 'L', 'XL', 'XXL'],
    female: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    male2: ['S', 'M', 'L', 'XL', 'XXL'],
    female2: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    male4: ['S', 'M', 'L', 'XL', 'XXL'],
    female4: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    male5: ['S', 'M', 'L', 'XL', 'XXL'],
    female5: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    female4numeric: ['S', 'M', 'L', 'XL', 'XXL'],
    male4numeric: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
  }

  const getAllowedSizes = (gender, size) => {
    let idx = sizesAllowed[gender].findIndex(s => s === size)
    if (idx<0){
      if (SIZE_SORT_ORDER.findIndex(s=>s===size)>SIZE_SORT_ORDER.findIndex(s=>s==='2XL')){
        // idx should be the last in array
        idx = sizesAllowed[gender].length-1
      }
    }
    const res = [sizesAllowed[gender][idx - 1], sizesAllowed[gender][idx], sizesAllowed[gender][idx + 1]]
    const undefinedLength = res.filter(s => s === undefined).length
    switch (undefinedLength) {
      case 0:
        return res;

      case 1:
        if (res.findIndex(s => s === undefined) === 0) {
          //1 at begining
          return [sizesAllowed[gender][idx], sizesAllowed[gender][idx+1]]
        } else {
          //1 at end
          return [sizesAllowed[gender][idx-1], sizesAllowed[gender][idx]]
        }
        
        case 2:
          if (res.findIndex(s => s === undefined) === 0) {
            //2 at begining
            return [sizesAllowed[gender][idx+1], sizesAllowed[gender][idx+2]]
          } else {
            //2 at end
            return [sizesAllowed[gender][idx-2], sizesAllowed[gender][idx-1]]
          }
      default:
        break;
    }
  }

  useEffect(() => {
   
      setFixedRecommendedSize(recommendedSize)
    
    // const allowedSizes = getAllowedSizes(gender,recommendedSize)
    // let idx = allowedSizes.findIndex(s=>s===recommendedSize)
    // if (idx<0){
    //   if (SIZE_SORT_ORDER.findIndex(s=>s===recommendedSize)>SIZE_SORT_ORDER.findIndex(s=>s==='2XL')){
    //     // idx should be the last in array
    //     idx = allowedSizes.length-1
    //   }
    // }
    // setSizesArray(allowedSizes)
    // setInputSize(idx<0 ? 0 : idx)

    return()=>{
      window.MYSZ_AVATAR = {loadedFrames: 0, precent: 0}
    }

  }, [])

  // useEffect(() => {
  //   var genderNum = gender === "male" ? 0 : 1;
  //   switch (fixedRecommendedSize) {
  //     case "XS":
  //       setSizesArray(["XS", "S"]);
  //       setInputSize(0);
  //       break;
  //     case "S":
  //       if (genderNum === 0) {
  //         setSizesArray(["S", "M"]);
  //         setInputSize(0);
  //       } else {
  //         setSizesArray(["XS", "S", "M"]);
  //         setInputSize(1);
  //       }
  //       break;
  //     case "M":
  //       setSizesArray(["S", "M", "L"]);
  //       setInputSize(1);
  //       break;
  //     case "L":
  //       setSizesArray(["M", "L", "XL"]);
  //       setInputSize(1);
  //       break;
  //     case "XL":
  //       if (genderNum === 0) {
  //         setSizesArray(["L", "XL", "XXL"]);
  //       } else {
  //         setSizesArray(["L", "XL"]);
  //       }
  //       setInputSize(1);
  //       break;
  //     case "XXL":
  //       setSizesArray(["XL", "XXL"]);
  //       setInputSize(1);
  //       break;
  //     default:
  //       break;
  //   }

  //   // setTimeout(() => {
  //   //   // console.log(avatarContainerRef.current.clientHeight, panelRef.current.clientHeight, switchRef.current.clientHeight, '||', avatarContainerRef.current.clientWidth, panelRef.current.clientWidth, switchRef.current.clientWidth);
  //   //   setElSize(Math.min(...[avatarContainerRef.current.clientHeight - panelRef.current.clientHeight - switchRef.current.clientHeight - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-top').slice(0, -2) - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-bottom').slice(0, -2),
  //   //   avatarContainerRef.current.clientWidth - panelRef.current.clientWidth - switchRef.current.clientWidth - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-left').slice(0, -2) - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-right').slice(0, -2)
  //   //   ].filter(size => size > 0)))
  //   // }, 10);

  // }, [fixedRecommendedSize])

  useEffect(() => {
    if (!avatarContainerRef.current || !panelRef.current || !switchRef.current) return
    setElSize(Math.min(...[avatarContainerRef.current.clientHeight - panelRef.current.clientHeight - switchRef.current.clientHeight - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-top').slice(0, -2) - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-bottom').slice(0, -2), avatarContainerRef.current.clientWidth - panelRef.current.clientWidth - switchRef.current.clientWidth - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-left').slice(0, -2) - window.getComputedStyle(avatarContainerRef.current, null).getPropertyValue('padding-right').slice(0, -2)].filter(size => size > 0)))
  }, [avatarContainerRef, panelRef, switchRef])




  console.log("sizesArray[inputSize] && recommendedSize",sizesArray[inputSize], recommendedSize)
  return (
    <div className='avatar-wrapper'>
      <div ref={avatarContainerRef} className="avatar-container">
        <div className="close-overlay" onClick={() => setShowAvatar(false)}></div>
        <img src={closeIconBlack} className="avatar-container__close" alt="close" />
        <div className="panel">
          <div ref={panelRef} className="panel__size">Your size is: {recommendedSize}</div>
          {/* {(gender === 'male' || gender === 'female') && <SwitchButtonSmallCircle
            state={inputSize}
            setState={setInputSize}
            elRef={switchRef}
          // rendererComponent={PAGE_STATES.VISUALISATION_PAGE}
          >
            {sizesArray}
          </SwitchButtonSmallCircle>} */}

          <div className="avatar">
            {/* {sizesArray[inputSize] && recommendedSize && <iframe src={`${process.env.REACT_APP_AVATAR_URL}?src=${gender}/${bodyType}/${recommendedSize.toLowerCase()}/${sizesArray[inputSize].toLowerCase()}`}></iframe>} */}
            {recommendedSize && <AvatarCmp gender={gender} bodyType={bodyType} recommendedSize={recommendedSize}  inputSize={inputSize} elSize={elSize} />}
          </div>
        </div>
      </div>
    </div>
  )
}
