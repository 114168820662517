// import SmallLogo from '../assets/img/smallLogo.svg';
// import Redo from '../assets/img/redo.svg';
// import Restart from '../assets/img/qr-restart.png';
import { useEffect, useState } from 'react';
import { NavMsg } from './NavMsg';
import Cart from '../assets/img/cart.svg';
import CartFull from '../assets/img/cart-green.svg';
import { ShoppingBag } from './checkOut/shoppingBag';
import { ShoppingMsg } from './checkOut/shoppingMsg';
import { Checkout } from './checkOut/checkOut';
import { withoutProperty } from '../helpers/helpers';
import { smallLogo, whiteRedo } from '../assets/img/staticImages';
import {config} from '../config'
// import { useEffect } from 'react';

export const Nav = ({ step, setStep, setAvatarShow, shoppingBag, setShoppingBag, mirrorData, shoppingMsg, setShoppingMsg, setShowFormBarcode , setScannerVisible }) => {
    const [showMsg, setShoeMsg] = useState(false)
    const [showShoppingBag, setShowShoppingBag] = useState(false)
    const [checkout, setCheckout] = useState(false)

    useEffect(() => {
        if (shoppingMsg.isShowShoppingBag) {
            setShowShoppingBag(shoppingMsg.isShowShoppingBag)
            const currShoppingMsg = withoutProperty(shoppingMsg, 'isShowShoppingBag')
            setShoppingMsg(currShoppingMsg)
        }
    },[shoppingMsg])

    // useEffect(() => {
    //     setShowShoppingBag(false)
    // }, [checkout])

    const switchUser = () => {
        config.SHOW_FORM_QR && setShowFormBarcode("FORM_QR");
        setAvatarShow(false)
        setShoeMsg(true);
    }



    return (
        <nav>
            {showShoppingBag && <ShoppingBag shoppingBag={shoppingBag} setShoppingBag={setShoppingBag} setShowShoppingBag={setShowShoppingBag} setCheckout={setCheckout} />}
            {shoppingMsg.isShow && <ShoppingMsg topImg={shoppingMsg.topImg} msg={shoppingMsg.msg} buttonText={shoppingMsg.buttonText} buttonCB={shoppingMsg.buttonCB} timer={shoppingMsg.timer} closeCB={()=>setShoppingMsg({isShow:false, isShowShoppingBag: false})} />}
            {checkout && <Checkout shoppingBag={shoppingBag} setShoppingBag={setShoppingBag} setCheckout={setCheckout} setShowShoppingBag={setShowShoppingBag} setStep={setStep} />}
            <div className='left-side'>
                <img className='logo' src={smallLogo} alt="Small Logo" />
                {(mirrorData.isDemo && step !== 2) && <button className='restart-btn' onClick={switchUser} data-analytics="NAV-restart">
                    {/* <img src={Restart} className="qr-restart" alt="Restart" /> */}
                    <div>
                        <img src={whiteRedo} className="redo" alt="Restart" /> Restart
                    </div>
                </button>}                
            </div>
            <div className="side-buttons">
                {mirrorData.isDemo && step !== 2  && <img src={shoppingBag.length ? CartFull : Cart} alt={shoppingBag.length ? "Full Cart" : "Empty Cart"} onClick={() => setShowShoppingBag(true)} />}
                {!(mirrorData.isDemo && step !== 2) && <button className='restart-btn' onClick={switchUser} data-analytics="NAV-restart">
                    {/* <img src={Restart} className="qr-restart" alt="Restart" /> */}
                    <div>
                        <img src={whiteRedo} className="redo" alt="Restart" /> Restart
                    </div>
                </button>}
                {/* {<button className='scan-btn restart-btn' onClick={()=>setScannerVisible(true)} data-analytics="NAV-scan">                    
                    <div>
                        Scan
                    </div>
                </button>} */}
            </div>
            <NavMsg onOk={() => setStep(1)} onCancel={() => setShoeMsg(false)} isShow={showMsg} />
        </nav>


        // <nav>
        //     <img src={SmallLogo} alt="logo"/>
        //     {step > 2 && <div className="cart" onClick={() => {
        //         setStep(1); 
        //         window.postMessage('MYSIZE-cart-empty', '*');
        //         window.oAppEnablementCommonInstance.hideBrowser()
        //     }}>
        //         { cartSize > 0 && <div className="cart__counter">{cartSize}</div>}
        //         <img src={cart} className="cart-icon" alt="cart icon" />
        //     </div>}
        // </nav>
    )
}