// import RemoveFromShoppingBag from '../../assets/img/remove-from-shopping-bag.svg';
// import ReduceItem from '../../assets/img/quantity-minus.svg';
// import TagIcon from '../../assets/img/tagIcon.svg';
import { minus, tagIcon, xRedIcon } from '../../assets/img/staticImages';

export const ItemCmp = ({ item, removeItem }) => {
    return (
        <>
            <div className='shopping-bag-item'>
                <img className='item-image' src={item.img_url || tagIcon} alt='Shopping item' />
                {removeItem && <img className='item-image-remove' src={item.quantity > 1 ? minus : xRedIcon} alt='Remove item' onClick={() => removeItem(item)} />}
                <div className='item-details'>
                    <label className='item-name'>{item.name} {'        '}</label>
                    <div className='item-size'>{item.size}</div>
                    <div className='item-qty'>Qty: {item.quantity}</div>
                    {/* <label className='item-variants'><span>{item.color}</span>, <span>{item.size}</span>, <span>{item.barcode}</span></label> */}
                    <label className='item-price'><span>{item.currency}</span><span>{item.price}</span></label>
                </div>
            </div>
            <hr />
        </>
    )
}