export const SIZE_SORT_ORDER = ['XXS', 'XS', 'S', 'SMALL', 'M', 'MEDIUM', 'L', 'LARGE', 'XL', 'X-LARGE', 'XXL', '2XL', 'XXXL', '3XL', 'XXXXL', '4XL', '5XL', '6XL'];
export const GENDER = {male: 0, female:1}
export const HEIGHT_UNIT = {cm: 0, in:1}
export const WEIGHT_UNIT = {kg: 0, lb:1}
export const BRA_UNIT = {us: 0, uk:1}
export const BODY_SHAPES = {flatter: 0, average:1, rounder:2}
export const BAND_SIZES = {
  us: ['30', '32', '34', '36', '38', '40', '42', '44', '46', '48'],
  uk: ['65', '70', '75', '80', '85', '90', '95', '100', '105', '110']
}
export const CUP_SIZES = {
  us: ['AA', 'A', 'B', 'C', 'D', 'DD', 'DDD', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'],
  uk: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O']
}

export function moveInArray(array, from, to, items = 1) {
    const newArray = array.slice()
    newArray.splice(to, 0, ...newArray.splice(from, items))
    return newArray
}

export function dateAdd(date, interval, units) {
    if(!(date instanceof Date))
      return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function() { if(ret.getDate() !== date.getDate()) ret.setDate(0);};
    switch(String(interval).toLowerCase()) {
      case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
      case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
      case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
      case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
      case 'day'    :  ret.setDate(ret.getDate() + units);  break;
      case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
      case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
      case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
      default       :  ret = undefined;  break;
    }
    return ret;
  }

export const getUniqueBy = (array, uniqueBy) => {
    const map = new Map([...new Set(array.map(v => [v[uniqueBy], v]))])

    return Array.from(map, ([key, value]) => {
        const obj = {}
        obj[uniqueBy] = key
        obj.originalObject = value
        return obj;
    });
}

export const getObjKey = (obj, value) => {
  return Object.keys(obj).find(key => obj[key] === value);
}

export const withoutProperty = (obj, property) => {  
  const { [property]: unused, ...rest } = obj

return rest
}