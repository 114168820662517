import { useEffect, useMemo, useRef, useState } from "react"
import { useSwipeable } from 'react-swipeable';
// import arrow from '../assets/img/rightArrow.svg';
import { rightArrow } from "../assets/img/staticImages";

export const Carousel2Cmp = ({ array, slidesToShow = 3, infiniteLoop = true }) => {
    const handlers = useSwipeable({
        onSwipedLeft: () => slideLeft(),
        onSwipedRight: () => slideRight(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    const [currSlide, setCurrSlide] = useState(array.length > 1 ? 1 : 0);
    const [slides, setSlides] = useState(array);

    const getArrayToShow = useMemo(() => {
        // console.log('currSlide', currSlide);
        const classes = ['before', 'center', 'after']
        if (slides.length===1 ){
            return [{ slide: slides[0], class: `${classes[1]} selected`}]
        } else if(slides.length===2){
            return slides.map((slide, idx) => { return { slide: slide, class: `${classes[idx] || 'hidden'}${idx===1 ? ' selected' : ''}` } })
        } else return slides.map((slide, idx) => { return { slide: slide, class: `${classes[idx] || 'hidden'}${idx===1 ? ' selected' : ''}` } })
    }, [slides, currSlide, slidesToShow])

    const slideLeft = () => {
        if (slides.length<=slidesToShow) return 
        // console.log('sliding left');
        // console.log('currSlide', currSlide);
        const len = slides.length - 1
        // setCurrSlide(currSlide - 1 < 0 ? len : currSlide - 1)
        const carouselEl = document.querySelector('.carousel2')
        const slideBefore = document.querySelector('.before')
        const slideCenter = document.querySelector('.center')
        const slideAfter = document.querySelector('.after')
        if (infiniteLoop){
            if (slideAfter===carouselEl.lastChild.previousSibling){
                carouselEl.firstChild.nextSibling.classList.add('after')
                carouselEl.firstChild.nextSibling.classList.remove('hidden')
            }else{
                slideAfter?.nextSibling?.classList.add('after')
            }
        } else {
            if (!slideAfter?.nextSibling.nextSibling) return
        }

        slideBefore?.classList.remove('before')
        slideCenter?.classList.remove('center')
        slideAfter?.classList.remove('after')
        slideAfter?.nextSibling?.classList.remove('hidden')
        slideAfter?.classList.add('center')
        slideCenter?.classList.add('before')
        slideBefore?.classList.add('hidden')
        // rotateLeft()
    }

    const slideRight = () => {
        if (slides.length<=slidesToShow) return
        // console.log('sliding right');
        // console.log('currSlide', currSlide);
        const len = slides.length - 1
        // setCurrSlide(currSlide + 1 < len ? 0 : currSlide + 1)
        const carouselEl = document.querySelector('.carousel2')
        const slideBefore = document.querySelector('.before')
        const slideCenter = document.querySelector('.center')
        const slideAfter = document.querySelector('.after')
        if (infiniteLoop){
            if (slideBefore === carouselEl.firstChild.nextSibling){
                carouselEl.lastChild.previousSibling.classList.add('before')
                carouselEl.lastChild.previousSibling.classList.remove('hidden')
            }else{
                slideBefore?.previousSibling?.classList.add('before')
            }
        } else {
            if (!slideBefore?.previousSibling.previousSibling) return
        }
        slideBefore?.classList.remove('before')
        slideCenter?.classList.remove('center')
        slideAfter?.classList.remove('after')
        slideBefore?.previousSibling?.classList.remove('hidden')
        slideBefore?.classList.add('center')
        slideCenter?.classList.add('after')
        slideAfter?.classList.add('hidden')
        
        // rotateRight()
    }

    function rotateLeft(arr = [...slides]) {
        let first = arr.shift();
        arr.push(first);
        setSlides(arr)
        return arr;
    }

    function rotateRight(arr = [...slides]) {
        let last = arr.pop();
        arr.unshift(last);
        setSlides(arr)
        return arr;
    }

    return (
        <div className={`carousel2 ${slides.length<=3 ? 'static' : ''}`} {...handlers}>
            <div className={`left-arrow ${slides.length>3 ? 'show' : ''}`} onClick={slideLeft}><img src={rightArrow} alt="arrow" /></div>
            {getArrayToShow.map((slide, idx) => <div className={`slide ${slide.class}`} key={`slide-${idx}`}>{slide.slide}</div>)}
            <div className={`right-arrow ${slides.length>3 ? 'show' : ''}`} onClick={slideRight}><img src={rightArrow} alt="arrow" /></div>
        </div>
    )
}