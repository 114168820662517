import React, { useState } from 'react';
import Webcam from "react-webcam";
import './Webcam.scss'
import camera from '../../assets/img/camera.svg';
import perentesis from '../../assets/img/perentesis.svg';
// import {ReactComponent as Arrow} from '../../assets/img/arrow.svg';
import { LoaderCmp } from '../LoaderCmp';


export const WebcamComponent = () => <Webcam />;



export const WebcamCapture = ({image,setImage, setConfirmPic, camError, setCamError, showLoader}) => {

    // const [image,setImage]=useState('');
    const webcamRef = React.useRef(null);
    const [showFlash, setShowFlash] = useState(false);

    
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
            // setShowPreview(true);
        });


    const takePictureHandler = (e) => {
        e.preventDefault();
        setShowFlash(true);
        
        setTimeout(() => {
            capture();
            setShowFlash(false);

        }, 1000);
    }

    return (
        <div className="webcam">
            {showLoader && <div className="webcam__loader"> <LoaderCmp/> </div>}
            {camError && !showLoader  && <div className="webcam__error">
                        <div className="webcam__error-text">
                            <p>{ camError }</p>
                            <div onClick={() => {
                                setImage('');
                                setCamError("")

                            }}>OK</div>
                        </div>
                        
                </div>
            }
            <div className={`webcam__flash ${showFlash ? "webcam__flash--on" : ""}`}></div>
           
            {!image && <div className="webcam__hint">
                <p>Hey There,</p>
                <p>Please position you face within the guides</p>
            </div>}
            {!showLoader && !image && <div className="webcam__perentesis">
                <img className="webcam__perentesis-left" src={perentesis}/>
                <img className="webcam__perentesis-right" src={perentesis}/>
            </div>}
            {image && !showLoader && <div className="webcam__preview">
                <div className="webcam__preview-btn webcam__preview-retake" onClick={() => setImage('')}>Retake</div>
                <div className="webcam__preview-btn webcam__preview-confirm" onClick={() => setConfirmPic()}>Use This photo</div>
            </div>}

            {image === '' ? <Webcam
                audio={false}
                // height={window.innerHeight}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={window.innerWidth}
                forceScreenshotSourceSize="false"
                videoConstraints={{
                    // width:'200px',
                    // height: '200px',
                    facingMode: "user",
                    // aspectRatio: true

                }}
            /> : <div>
                <img src={image} width={window.innerWidth}/>
            </div>}
            

            {!image && !showLoader && <div className="camera-btn">
                <div className="webcam-btn" onClick={takePictureHandler}>
                    <img src={camera}/>
                </div>
            </div>}
        </div>
    );
};