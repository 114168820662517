import React, { useEffect, useRef, useState } from 'react';
import { scan, initScanner, getUserMeasurements } from './services/ScannerService';
import { UserService } from './services/UserService';
import { ProductService } from './services/ProductService';
import { Nav } from './cmps/Nav';
import { LoaderCmp } from './cmps/LoaderCmp';
import { ErrorMsg } from './cmps/ErrorMsg';
import { Step1 } from './cmps/Step1';
import { Step2 } from './cmps/Step2';
import { Step3 } from './cmps/Step3';
// import logo from './logo.svg';
import './assets/style.scss';
import { config } from './config';
import { InstallScreen } from './cmps/InstallScreen';
import { getUniqueBy, SIZE_SORT_ORDER, BODY_SHAPES, GENDER, HEIGHT_UNIT, WEIGHT_UNIT, BRA_UNIT, getObjKey, BAND_SIZES } from './helpers/helpers';
import { clearFLSMstate, gtagCustomEvent, setFLSMstate } from './services/GtagService';
import { Form } from './cmps/Form';
import { ShoppingMsg } from './cmps/checkOut/shoppingMsg';
import { ScannerIframe } from './cmps/Scan/ScannerIframe';


const ERROR_TIME_OUT = 2000;
const SCANNER_DEBOUNCE_TIME = 2000;
const IDLE_TIMEOUT = 60 * 1000 * 5;
const IDLE_RESTART_TIMEOUT = 20 * 1000;

let timer;

// if (process.env.REACT_APP_ENV_NAME === "production") console.log = function () {};

const App = () => {
  const [isScannerVisible, setScannerVisible] = useState(false)
  const [step, setStep] = useState(0)
  const [scannedItem, setScannedItem] = useState(undefined)
  const [user, setUser] = useState(null)
  const [variant, setVariant] = useState(null)
  const [product, setProduct] = useState(null)
  const [error, setError] = useState(undefined)
  const [mirrorData, setMirrorData] = useState({})
  const [avatarShow, setAvatarShow] = useState(false)
  const [shoppingBag, setShoppingBag] = useState([])
  const [shoppingMsg, setShoppingMsg] = useState({ isShow: false })
  const [isShowIdleMsg, setIsShowIdleMsg] = useState(false)
  const [debugValue, setDebugValue] = useState()
  const [showFormBarcode, setShowFormBarcode] = useState((config.SHOW_FORM_QR && "FORM_QR") || null);
  const [isFreshSession, setIsFreshSession] = useState(true);


  const lastScannedItemTime = useRef()
  const idleTimer = useRef()

  useEffect(() => {
    // console.log("FOOTER BTNS: ", process.env.REACT_APP_SHOW_FOOTER_BTNS);
    initScanner()
    document.body.addEventListener('keypress', handleScanner)

    document.body.addEventListener('keypress', restartIdleTimer)
    document.body.addEventListener('click', restartIdleTimer)

    return () => {
      document.body.removeEventListener('keypress', handleScanner)
      window.SCAN = undefined;
      clearTimeout(timer);
      clearTimeout(idleTimer.current);
    }
  }, [])

  useEffect(() => {
    // console.log('Shopping bag:', shoppingBag);
  }, [shoppingBag])

  useEffect(() => {
    gtagCustomEvent('MIRROR_DATA_CHANGE', mirrorData);
    setFLSMstate("RETAILER_DATA", mirrorData);
    let localStorageMirrorData = null;
    try {
       localStorageMirrorData = JSON.parse(localStorage.getItem(`${config.PREFIX}mirror-data`));
      
    } catch (error) {
      localStorageMirrorData = {};
    }
    if(!localStorageMirrorData?.retailerToken || localStorageMirrorData.retailerToken === '') {
      localStorage.removeItem(`${config.PREFIX}mirror-data`)
      localStorage.removeItem(`SHOW_FORM_QR`)
      setStep(0);
      return;
    }
    // if (Object.keys(JSON.parse(localStorage.getItem(`${config.PREFIX}mirror-data`))).length && ) alert()
    if (Object.keys(JSON.parse(localStorage.getItem(`${config.PREFIX}mirror-data`) || '{}')).length) setStep(1)
  }, [mirrorData])

  const restartIdleTimer = () => {
    clearTimeout(idleTimer.current);
    startIdleTimer()
  }

  const startIdleTimer = () => {
    idleTimer.current = setTimeout(() => {
      setIsShowIdleMsg(true)
      idleTimer.current = null
    }, IDLE_TIMEOUT)
  }

  useEffect(() => {
    //send GTAG custom event
    step !== 'loader' && gtagCustomEvent('STEP_CHANGE', step);
    if (step === 1) clearFLSMstate();

    // check retailer code and mirror name from local storage
    !Object.keys(mirrorData).length && setMirrorData(JSON.parse(localStorage.getItem(`${config.PREFIX}mirror-data`) || '{}'))

    // start timeout for idle
    if (step >= 2 && !idleTimer.current) {
      restartIdleTimer()
    } else {
      clearTimeout(idleTimer.current);
    }

    if (step > 0) {
      window.SCAN = (userBarcode) => {
        if (showFormBarcode === "FORM_QR") return;
        setAvatarShow(false)
        if (typeof userBarcode !== 'string') {
          try {
            let e = new Error('Only STRINGS inputs are allowed. Please try again in this format: SCAN("123")');
            e.name = 'Wrong input!  => ';
            // e.stack = '';
            throw e;
          } catch (e) {
            console.error(e.name, e.message);
          }
        } else {
          userBarcode.split('').forEach(char => {
            handleScanner({ key: char })
          });
          handleScanner({ key: 'Enter' })
        }
      }
    }

    initScanner()
    switch (step) {
      case 2:
        setVariant(null);
        setIsFreshSession(true);
        break;

      default:
        break;
    }
  }, [step, showFormBarcode])

  useEffect(() => {
    if (lastScannedItemTime.current + SCANNER_DEBOUNCE_TIME > Date.now()) return
    lastScannedItemTime.current = null
    if (process.env.REACT_APP_INSTORE === 'true' && step < 2) {
      return;
    };
    if (!scannedItem) return
    if (step !== 0) setStep('loader');
    if (scannedItem?.scanned === 'restart') {
      setStep(1);
      return
    }
    lastScannedItemTime.current = Date.now()
    switch (step) {
      case 1:
        setShoppingBag([]);
        // get the user details from API
        // UserService.getUser(scannedItem.scanned).then((user) => {
        //   if (user.code > 400) {
        //     gtagCustomEvent('USER_ERROR', { user, scannedItem });
        //     setStep(1);
        //     setError({
        //       type: 'normal',
        //       timer: ERROR_TIME_OUT,
        //       body: <>
        //         <span>Sorry,</span>
        //         <br />
        //         Your profile didn't load
        //       </>
        //     });
        //     timer = setTimeout(() => { setError(undefined) }, ERROR_TIME_OUT);
        //   } else {
        //     if (user.bodyType === 0) user.bodyType = 1
        //     setFLSMstate("USER", user)
        //     setUser(user)
        //     setStep(2)
        //   }
        // })
        (async () => {
          try {
            console.log("sergeyx", scannedItem);
            let userScanned = null;
            if(scannedItem.scanned.split(',').length > 1) {
              userScanned = getUserMeasurements(scannedItem.scanned);
            } else { 
              userScanned = await UserService.getUser(scannedItem.scanned);
              if(!userScanned.code){
                userScanned.measurements = userScanned.measurements.reduce((a, v) => ({ ...a, [v.measurement_type.name]: v.value}),{});
                if(userScanned.measurement_system === 'imperial') { 
                  userScanned.measurements.height *= 0.393701;
                  userScanned.measurements.weight *= 2.204623;
                  
                }
              } else{
                if(userScanned.code === 404) throw Error("User not found...")
                else throw Error("Somthing went wrong...")
              }
            }

            if (userScanned.body_type === 0) userScanned.body_type = 1
            setFLSMstate("USER", userScanned)
            setUser(userScanned)
            setStep(2)
          } catch (error) {
            setStep(1);
            setError({
              type: 'normal',
              timer: ERROR_TIME_OUT,
              body: <>
                <span>Sorry,</span>
                <br />
                {error.message}
              </>
            });
            timer = setTimeout(() => { setError(undefined) }, ERROR_TIME_OUT);
          }
        })(scannedItem.scanned);
        break;
      case 2:
      case 3: {
        // ProductService.getVariant(scannedItem.scanned, user.token, mirrorData).then((variant) => {
        //   if (variant.code >= 400 || variant.status >= 400) {
        //     setError({
        //       type: 'normal',
        //       timer: ERROR_TIME_OUT,
        //       body:
        //         <>
        //           <span>Sorry,</span>
        //           <br />
        //           The item didn't load
        //           {/* <br />
        //             <button onClick={() => setError(undefined)}>OK</button> */}
        //         </>
        //     });
        //     timer = setTimeout(() => { setError(undefined); setStep(Math.min(step, 3)) }, ERROR_TIME_OUT);
        //   } else {
        if (process.env.REACT_APP_INSTORE === 'true') setScannerVisible(false);
        ProductService.getProduct(scannedItem.scanned, null, mirrorData).then((product) => {
          console.log('PRODUCT:', product);
          if (!product || product.code >= 400 || !('variants' in product)) {
            gtagCustomEvent('PRODUCT_ERROR', { product, scannedItem });
            setError({
              type: 'normal',
              timer: ERROR_TIME_OUT,
              body:
                <>
                  <span>Sorry,</span>
                  <br />
                  The item didn't load
                  {/* <br />
                              <button onClick={() => setError(undefined)}>OK</button> */}
                </>
            });
            timer = setTimeout(() => { if (process.env.REACT_APP_INSTORE === 'true') setScannerVisible(true); setError(undefined); setStep(Math.min(step, 3)) }, ERROR_TIME_OUT);
          } else {
            setFLSMstate("PRODUCT", product)
            product.variants = product?.variants.map(variant => { return { ...variant, color: variant.color.toUpperCase(), size: variant.size.toUpperCase() } })
            const variant = product.variants.find(variant => variant.barcode === scannedItem.scanned)
            if (!variant) {
              gtagCustomEvent('VARIANT_ERROR', { variants: product.variants, scannedItem });
            }
            if (!('code' in product && product.code > 200)) {
              const availableSizes = getUniqueBy(product.variants, 'size').sort((a, b) => SIZE_SORT_ORDER.indexOf(a.size) - SIZE_SORT_ORDER.indexOf(b.size))
              console.log('avail sizes:', availableSizes);
              product.size = availableSizes
            }
            setFLSMstate("VARIANT", variant);
            const body = { ...user, size_chart_code: product.size_chart_code, retailer_token: mirrorData.retailerToken }
            console.log({body});
            ProductService.getGarmentSize(body, mirrorData.retailerToken).then(garmentSize => {
              product.updatedAt = Date.now();
              product.recommended_size = garmentSize.size;
              console.log({ product });
              setProduct(product);
              setVariant(variant);
              setStep(3);
            })
          }
        })
        break;
      }

      default:
        break;
    }
  }, [scannedItem])

  const setFormData = async (data) => {
    setStep('loader')
    // console.log(data);
    const parsedData = {
      gender: getObjKey(GENDER, data.gender),
      heightUnit: getObjKey(HEIGHT_UNIT, data.heightUnit),
      height: parseInt(data.height),
      heightInch: parseInt(data.heightInch),
      weightUnit: getObjKey(WEIGHT_UNIT, data.weightUnit),
      weight: parseInt(data.weight),
      knownBraSize: data.knownBraSize ? 'yes' : 'not_sure',
      braUnit: getObjKey(BRA_UNIT, data.braUnit),
      bandSize: data.bandSize.value,
      cupSize: data.cupSize.value,
      bellyShape: getObjKey(BODY_SHAPES, data.bellyShape),
      hipShape: getObjKey(BODY_SHAPES, data.hipShape)
    }

    if (data.heightUnit === HEIGHT_UNIT.cm) {
      ['heightInch'].forEach(e => delete parsedData[e])
    }
    if (data.gender === GENDER.male) {
      ['knownBraSize', 'braUnit', 'bandSize', 'hipShape'].forEach(e => delete parsedData[e])
    }
    if (data.gender === GENDER.female && !data.knownBraSize) {
      ['braUnit', 'bandSize', 'cupSize'].forEach(e => delete parsedData[e])
    }
    console.log(parsedData);

    // create guest user
    const measurements = [
      {
        measurement_type_name: "height",
        value: parsedData.heightUnit === HEIGHT_UNIT.in ? parsedData.height * 30.48 + parsedData.heightInch * 2.45 : parsedData.height,
      },
      {
        measurement_type_name: "weight",
        value: parsedData.weightUnit === WEIGHT_UNIT.lb ? Math.round(parsedData.weight / 2.20462) : Math.round(parsedData.weight),
      },
      {
        measurement_type_name: "bra_band",
        value: data.gender === GENDER.female && parsedData.knownBraSize === 'not_sure'
          ? "999"
          : data.gender === GENDER.male
            ? 0
            : BAND_SIZES['uk'][BAND_SIZES[parsedData.braUnit].findIndex(v => v === parsedData.bandSize)],
      },
      {
        measurement_type_name: "bra_cup",
        value: parsedData.knownBraSize === 'not_sure' && parsedData.gender === GENDER.female ? "999" : parsedData.cupSize,
      },
      {
        measurement_type_name: "bra_unit",
        value: parsedData.knownBraSize === 'not_sure' && parsedData.gender === GENDER.female ? "999" : parsedData.braUnit,
      },
      { measurement_type_name: "belly_shape", value: data.bellyShape + 1 },
      {
        measurement_type_name: "hip_shape",
        value: parsedData.gender === GENDER.female ? data.hipShape + 1 : 0,
      }
    ];

    localStorage.setItem('MYSIZE_USER_DATA', JSON.stringify(measurements))

    const mysizeUser = {
      "id": 45813,
      "full_name": "YhELEkZEH8PNvIYWhOvOVwrmQkG2",
      "email": "YhELEkZEH8PNvIYWhOvOVwrmQkG2@firebase.com",
      "age": null,
      "gender": data.gender,
      "fitting_preference": "normal",
      "measurement_system": parsedData.heightUnit === 'cm' ? "metric" : "imperial",
      "is_user_height_manual": true,
      "measurement_percentage": 0,
      measurements,
      "profile_barcode": "458131679575675",
      "favorite_shoe_brand_name": null,
      "favorite_shoe_category_name": null,
      "favorite_shoe_category_id": null,
      "favorite_shoe_brand_id": null,
      "favorite_shoe_region": null,
      "favorite_shoe_size": null,
      "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk3OWVkMTU1OTdhYjM1Zjc4MjljZTc0NDMwN2I3OTNiN2ViZWIyZjAiLCJ0eXAiOiJKV1QifQ.eyJwcm92aWRlcl9pZCI6ImFub255bW91cyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9teXNpemVpZC1yZXRhaWxlcnMtc3RhZ2luZyIsImF1ZCI6Im15c2l6ZWlkLXJldGFpbGVycy1zdGFnaW5nIiwiYXV0aF90aW1lIjoxNjc5NTc1Njc0LCJ1c2VyX2lkIjoiWWhFTEVrWkVIOFBOdklZV2hPdk9Wd3JtUWtHMiIsInN1YiI6IlloRUxFa1pFSDhQTnZJWVdoT3ZPVndybVFrRzIiLCJpYXQiOjE2Nzk1NzU2NzQsImV4cCI6MTY3OTU3OTI3NCwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6e30sInNpZ25faW5fcHJvdmlkZXIiOiJhbm9ueW1vdXMifX0.TM4qeWB36qDJPFnEWJeg3jLAX6X2_IJ9e4dVQ2xJsNp9RPifNNXVgWOOXTeVMRGm9fxXl8bYTqf29osM9Iz-oHXvpyaAuqifrx5O8mcAjXYjBUBBGtuPCMuPkvgfqTEEdrYO4jRXuWa0XfpyuvweDVI-i3UNjn6oRZJW1kcSArBNOF3Z74hSDpfftF-viMpzG6c5RybfCdYBF4WBt4_7mpyqwclHqJS8Y-kwws6KOxrjxi_1Jf2gQT-sa2tOT2qfW1m828py2QZUe4_h7QzDPIToUGWOywJWKVxBpIXDvBblg8jHTL29E9FdmEA_RyACtP8t5kx5WMKikt1n5YtBfA"
    }
    if (mysizeUser.bodyType === 0) mysizeUser.bodyType = 1
    setFLSMstate("USER", mysizeUser)
    setUser(mysizeUser)
    setStep(2)

  }

  const debugClick = () => {
    if (document.querySelector('#debugInput').value.length === 0) return
    const debugBarcodes = JSON.parse(window.localStorage.getItem('debug_footer') || '[]')
    const newBcd = document.querySelector('#debugInput')?.value
    if (debugBarcodes.findIndex(bcd => bcd === newBcd) >= 0) return
    debugBarcodes.push(newBcd)
    console.log(debugBarcodes);
    window.localStorage.setItem('debug_footer', JSON.stringify(debugBarcodes))
    setDebugValue('')
  }

  const removeDebugBtn = (ev) => {
    ev.stopPropagation();
    const bcd = ev.target.parentElement.innerText.replace('SCAN ', '').slice(0, -1);
    const debugBarcodes = JSON.parse(window.localStorage.getItem('debug_footer') || '[]');
    const idx = debugBarcodes.findIndex(el => el === bcd);
    if (idx >= 0) {
      debugBarcodes.splice(idx, 1);
      window.localStorage.setItem('debug_footer', JSON.stringify(debugBarcodes));
    }
    // ev.target.parentElement.remove();
    setDebugValue(bcd)
  }

  const handleScanner = ({ key }) => {
    if (avatarShow) setAvatarShow(false)
    const scanned = scan(key)
    // config.SHOW_FORM_QR && setShowFormBarcode("FORM_QR");
    if (scanned) {
      gtagCustomEvent('SCANNED_ITEM', scanned);
      setScannedItem({ scanned })
      initScanner();
    }
  }

  const isForm = new URLSearchParams(window.location.search).get('isForm') === 'true' || process.env.REACT_APP_INSTORE === 'true'
  return (
    isScannerVisible ?
      <div className="App">
        <ScannerIframe setScannerVisible={setScannerVisible} setIsFreshSession={setIsFreshSession} ></ScannerIframe>
      </div>
      :
      isShowIdleMsg ? <ShoppingMsg msg={`Are you still there?\nIf not, we'll restart this session in:\n<%timer%>`} timer={IDLE_RESTART_TIMEOUT} buttonText="I'm Here" buttonCB={() => { restartIdleTimer(); setIsShowIdleMsg(false) }} closeCB={() => { setIsShowIdleMsg(false); setStep(1) }} />
        : <main>
          {step > 1 && <Nav setStep={setStep} step={step} setAvatarShow={setAvatarShow} shoppingBag={shoppingBag} setShoppingBag={setShoppingBag} mirrorData={mirrorData} shoppingMsg={shoppingMsg} setShoppingMsg={setShoppingMsg} setShowFormBarcode={setShowFormBarcode} setScannerVisible={setScannerVisible} />}
          {step === 'loader' && <LoaderCmp />}
          {step === 0 && !Object.keys(mirrorData).length && <InstallScreen setStep={setStep} setShowFormBarcode={setShowFormBarcode} />}
          {/* {step === 1 && Object.keys(mirrorData).length && <Step1 />} */}
          {step === 1 && Object.keys(mirrorData).length && (isForm || config.isShowForm === 'true' ? <Form setData={setFormData} /> : <Step1 showFormBarcode={showFormBarcode} setShowFormBarcode={setShowFormBarcode} />)}
          {step === 2 && <Step2 user={user} acceptScannerClick={isFreshSession} />}
          {step === 3 && <Step3 user={user} variant={variant} setVariant={setVariant} setStep={setStep} setScannedItem={setScannedItem} mirrorData={mirrorData} product={product} setShowAvatar={setAvatarShow} showAvatar={avatarShow} shoppingBag={shoppingBag} setShoppingBag={setShoppingBag} setScannerVisible={setScannerVisible} />}
          {error && <ErrorMsg type={error.type} timer={error.timer} nextStep={error.next}>{error.body}</ErrorMsg>}
          <div className="version">
            <label>{`Version: ${config.VERSION} FLSM ${process.env.REACT_APP_ENV_NAME !== 'production' ? process.env.REACT_APP_ENV_NAME : ''}`}</label>
            {process.env.REACT_APP_ENV_NAME !== 'production' && <div id="debug-btns" style={{ display: 'inline-block', marginInline: '20px' }}>
              {(!window.localStorage.getItem('debug_footer')) && window.localStorage.setItem('debug_footer', JSON.stringify(['446251657780457', '123456789001', '123456789002']))}
              <input id="debugInput" type="search" onChange={({ target }) => { setDebugValue(target.value) }} />
              <button onClick={debugClick}>ADD</button>
              {JSON.parse(window.localStorage.getItem('debug_footer') || '[]').map((val, idx) => <button key={idx} onClick={() => { window.SCAN(val) }}>{`SCAN ${val}`}<button onClick={removeDebugBtn}>X</button></button>)}
              <button onClick={() => { window.localStorage.removeItem('debug_footer') }}>RESET</button>
            </div>}
          </div>
          {/* <div className="version">{`Version: 1.2.0 width:${window.innerWidth}px height:${window.innerHeight}px`}</div> */}
        </main>
  );
}

export default App;
