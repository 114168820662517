import { moveInArray } from '../helpers/helpers';
import { Carousel2Cmp } from './Carousel2Cmp';
import { Carousel } from './CarouselCmp';

export const ColorCmp = ({ colors, onClick, value }) => {

    const onColorClick = (ev) => {
        document.querySelector('.carousel2 .selected').classList.remove('selected')
        ev.target.parentElement.parentElement.classList.add('selected')
        onClick(ev)
    }

    const colorsArr = moveInArray(colors, colors.findIndex(color => color.color===value), colors.length > 1 ? 1 : 0).map((color, idx, array) =>
        <div className={`container-slide`} key={`colorCmp${idx}`}>
            <div className="color" data-barcode={color.barcode} data-analytics="STEP3-color" style={{ '--color': color.color, '--idx': idx, '--total': array.length }} onClick={onColorClick}> </div>
        </div>
    )

    return (
        <div className="color-cmp">
            {/* <Carousel slides={colorsArr} arrows={false} /> */}
            <Carousel2Cmp array={colorsArr}  />
        </div>
    )
}