
// const api_base_url = process.env.REACT_APP_API_BASE_URL;
// const ROOT_URL = `${api_base_url}/api/v1/gk/users/me`;
// const ROOT_URL = `https://api.mysz.io/api/v1/`;
// 
// const ROOT_URL = "http://localhost:3001/api/v1";
// const ROOT_URL = "http://localhost:3000/api/v1";
// const ROOT_URL = "https://api-qa.mysz.io/api/v1";


import { config } from '../config';

const ROOT_URL = config[process.env.REACT_APP_ENV_NAME].BACKEND_URL;


export const HttpService = {
    get,
    post
}


// constructor(url_prefix = "") {
//     this.url_prefix = url_prefix;
//     this.getHeaders();
// }

async function get(url, queryParams, userToken = null) {

    var headers = _getHeaders(userToken);
    console.log(ROOT_URL + url + '?' + _mapQueryParams(queryParams));
    try {
        let response = await fetch(
            ROOT_URL + url + '?' + _mapQueryParams(queryParams),
            {
                headers
            }
        );
        let jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        return null;
    }
}

async function post(url, queryParams = null, body = null, header = null, asForm = false) {
    try {
        const headers = asForm ? ({ 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' }) : _getHeaders(header || '');
        const response = await fetch(
            url + '?' + _mapQueryParams(queryParams),
            {
                method: "POST",
                headers,
                body: asForm ? new URLSearchParams(_jsonToFormData(body)) : JSON.stringify(body),
            }
        );

        let jsonResponse = await response.json();
        if (!(('no_rec_reason' in jsonResponse) || ('size' in jsonResponse))) {
            asForm && (jsonResponse = await post(url, queryParams, body, header, false));
        }
        return jsonResponse;
    } catch (error) {
        return null;
    }
}

// async put(url, body, queryParams = null) {
//     try {
//         let response = await fetch(
//             ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
//             {
//                 method: "PUT",
//                 headers: this.headers,
//                 body: JSON.stringify(body),
//             }
//         );
//         let jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         return null;
//     }
// }

// async remove(url, queryParams = null) {
//     try {
//         let response = await fetch(
//             ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
//             {
//                 method: "DELETE",
//                 headers: this.headers,
//             }
//         );
//         let jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         return null;
//     }
// }

// getUrl(url) {
//     return this.url_prefix + url;
// }

function _getHeaders(userToken) {
    console.log({userToken});
    return ({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(userToken && {'X-Auth_token': userToken})
    });
}

function _mapQueryParams(queryParams) {
    return queryParams
        ? Object.keys(queryParams)
            .map(function (key) {
                return key + "=" + queryParams[key];
            })
            .join("&")
        : "";
}

const _jsonToFormData = (data, formData = new FormData(), parentKey, isMain = true) => {
    let idx = Array.isArray(data) ? data.length - 1 : undefined;
    if (data && typeof data === 'object' && !Array.isArray(data) && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key, index) => {
            idx = index;
            _jsonToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key, false);
        });
    } else {
        const value = data == null ? '' : data;

        if (Array.isArray(value)) {
            value.forEach(val => formData.append(`${parentKey || '0'}[]`, val));
        } else {
            value && formData.append(parentKey || '0', value);
        }
    }
    if (isMain && data && idx === ((typeof data === 'object' && !Array.isArray(data)) ? Object.keys(data).length - 1 : (Array.isArray(data) ? data.length - 1 : undefined))) return formData
}