import { useCallback, useEffect, useRef, useState } from "react"
import { config } from "../config"
// import Logo from '../assets/img/logo.svg';
import { HttpService } from "../services/HttpService";
import { Loader } from "../assets/img/loader-inherit-color";
import { logo } from "../assets/img/staticImages";
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';


export const InstallScreen = ({setStep, setShowFormBarcode} ) => {

    const [retailerCode, setRetailerCode] = useState('')
    const [mirrorName, setMirrorName] = useState('')
    const [showFormQr, setShowFormQr] = useState(false);
    
    const isDemoRef = useRef(false)
    const retailerTokenRef = useRef()
    const ref = useRef()
    
    let timer;

    const isFormValid = useCallback(() => {
        return retailerCode.length && mirrorName.length
    },[retailerCode, mirrorName]);

    useEffect(() => {
        ref.current.disabled = !isFormValid()
    }, [retailerCode, mirrorName])

    const handleChange = ({ target }) => {
        switch (target.name) {
            case 'retailer-code':
                setRetailerCode(target.value.trim())
                break;
            case 'mirror-name':
                setMirrorName(target.value.trim())
                break;
            default:
                break;
        }
    }

    const isRetailerValid = async () => {
        const BASE_URL = "flsm"
        const retailer = await HttpService.get(`/${BASE_URL}/retailers/me`, {retailer_code: retailerCode})
        if ('retailer_token' in retailer){
            isDemoRef.current = retailer.is_demo
            retailerTokenRef.current = retailer.retailer_token
            return true
        } 

        ref.current.nextElementSibling.style.opacity=1
        clearTimeout(timer)
        timer = setTimeout(() => ref.current.nextElementSibling.style.opacity=0, 3000)
        return false
    }

    const onSubmit = async (ev) => {
        ev.preventDefault();
        ref.current.disabled=true;
        ref.current.innerHTML = `<div class="ver-btn">${Loader(true)}<label>Verifing...</label></div>`
        if (isFormValid() && await isRetailerValid()) {
            const mirrorData = {
                retailerCode,
                mirrorName,
                isDemo: isDemoRef.current,
                retailerToken: retailerTokenRef.current
            }
            localStorage.setItem(`${config.PREFIX}mirror-data`, JSON.stringify(mirrorData));
            localStorage.setItem("SHOW_FORM_QR", showFormQr);
            config.SHOW_FORM_QR = showFormQr;
            setShowFormBarcode(showFormQr ? "FORM_QR" : null);
            setStep(1)
        } else {
            ref.current.innerText = "Save"
            ref.current.disabled=false;
        }
    }

    return (
        <section className="install-screen">
            <img className="logo" src={logo} alt="MYSIZE logo" />
            <h1>Please fill the requested fields,<br/>as supplied by MySizeId <sup className="ltd">LTD</sup></h1>
            <form onSubmit={onSubmit} name="install">
                <div className="form-input">
                    <label htmlFor="retailer-code">Retailer Code</label>
                    <input id="retailer-code" name="retailer-code" value={retailerCode} onChange={handleChange} autoFocus={true} />
                </div>
                <div className="form-input margin-top-25">
                    <label htmlFor="mirror-name">Mirror Station Name</label>
                    <input id="mirror-name" name="mirror-name" value={mirrorName} onChange={handleChange} />
                </div>
                <span style={{alignSelf: "flex-start", paddingLeft: "20px", marginTop:"30px"}}>

                    <FormControlLabel
                        label='Show "form.mysz.io" QR Code'
                        control={<Checkbox checked={showFormQr} onChange={e => setShowFormQr(e.target.checked)}  sx={{color: "#f8686e",'&.Mui-checked': {color: "#f8686e",},}}/>}
                    />
                </span>
                <button ref={ref} type="submit">Save</button>
                <div className="form-validation-message">Retailer code is invalid</div>

            </form>
        </section >
    )
}