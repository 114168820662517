// import Loader from '../assets/img/loader.svg';
import { loader } from '../assets/img/staticImages';

export const LoaderCmp = () => {
    return (
        <section className="loader">
            <img src={loader} alt="Loader"/>
            Loading...
        </section>
    )
}