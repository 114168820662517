import React, {useEffect, useState} from 'react'
import {WebcamComponent, WebcamCapture} from './Webcam/Webcam';
import closeIcon from '../assets/img/close.png'



const WEBCAM_WARNINGS = {
    FACE_SMALL:	"Move closer to the camera.",
    HEAD_CLOSE:	"Move further away from the camera.",
    LIGHT_ON_LEFT:	"Ensure the face is lit more evenly.",
    LIGHT_ON_RIGHT:	"Ensure the face is lit more evenly.",
    HAIR_AT_EDGE:	"Move further away from the camera so the hair does not go off the edges of the image.",
    FACE_LOOKING_DOWN:	"Ensure the face is pointed directly towards the camera.",
    NECK_OBSTRUCTED: "Ensure the neck is clearly visible and not obstructed, with the face pointing directly towards the camera.",
    LOW_QUALITY: "Ensure the face is clearly visible and not obstructed.",
    NO_FACE: "No face"
}

const WEBCAM_ERRORS = {
    NO_FACE:	"Ensure the face is in the centre of the image.",
    TOO_MANY_FACES:	"Ensure there is only one face in the image.",
    FACE_TOO_CLOSE:	"Move further away from the camera.",
    FACE_TOO_SMALL:	"Move closer to the camera.",
    FACE_TOO_NEAR_EDGE:	"Ensure the face is in the centre of the image and not close to the edges.",
    FACE_BLURRY:	"Ensure the camera is stable and in focus before taking the image.",
    FACE_LOOKING_TOO_DOWN:	"Ensure the face is pointed directly towards the camera.",
    NECK_TOO_OBSTRUCTED:	"Ensure the neck is clearly visible and not obstructed, with the face pointed directly towards the camera.",
    FACE_TOO_TURNED:	"Ensure the face is pointed directly towards the camera."
}


export default function TryOn({user, showTryOnHandler, setTryOnImage}) {
    // const [image, setImage] = useState("")
    const [res, setRes] = useState(null)
    const [camError, setCamError] = useState(null)
    const [showLoader, setShowLoader] = useState(false)
    const [image, setImage] = useState("");
    

    const confirmPicHandler = async() => {
        setShowLoader(true);
        const userId = 'uda57b74dba910b2f7552b4fd';
        const outfit1 = 'o0ebe069203ca858c54a8c330';
        const authToken = 'Basic dWRhNTdiNzRkYmE5MTBiMmY3NTUyYjRmZDp0Y0dJWTZpR1NhZFYwVkljSEFjRzlNdFp1';

        const userMeasurements = user.measurements
        const postMeasurements = {};
     

        for (const measurement of userMeasurements) {
            var measurementName =measurement.measurement_type.name
            postMeasurements[measurementName] = measurement.value;
        }

        const x = await fetch(`https://api.zyler.com/v1/users/${userId}`, {
            headers: {'Authorization': authToken,
            'Content-Type': 'application/json',},
            method: 'POST',
            body:  JSON.stringify( {
                measurements: {
                    "clothing_locale": "UK",
                    "height": Number(postMeasurements.height),
                    "dress_size": 22,
                    "bra_back": 44,
                    "bra_cup": "M",
                    "population_category": "AdultFemale",
                }})
        });


            // UPLOAD SELFIE PIC
            const formData = new FormData();
            formData.append('image', image.replace("data:image/jpeg;base64,",""));
            const uploadProfileResponse = await fetch(`https://api.zyler.com/v1/users/${userId}/selfies`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': authToken,
                    
                }
            })

            const res = await uploadProfileResponse.json(); 
            if(res?.result?.error) {
                console.log("res.result.error",res.result.error);
                console.log("WEBCAM_ERRORS[res.result.error]", WEBCAM_ERRORS[res.result.error]);
                setCamError(WEBCAM_ERRORS[res.result.error]);
                setShowLoader(false);
                return;
            }

    
             const selfieResponse = await fetch(`https://api.zyler.com/v1/users/${userId}/outfits/${outfit1}.jpg?height=${window.screen.height}&v=${Math.random().toString(36).slice(2)}`, {
                headers: {
                'Authorization': authToken,
                },})
            .then(res => res.blob())
            .then(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                    var base64data = reader.result;                
                    setRes(base64data);
                    setTryOnImage(base64data);
                    setShowLoader(false);
            }});

           
           
    }

    const closeImageHandler = () => {

        showTryOnHandler(false);
    }

  return (

    <div>
        {res ? <div style={{margin: "auto", display: "flex", justifyContent: "center", zoom: 0.75}}>
            <img style={{position: 'absolute', right: 0, top: 0, cursor: 'pointer'}} src={closeIcon} onClick={closeImageHandler}/>
            <img src={res} height="100%"/>

        </div> :  
        <WebcamCapture 
            image={image} 
            setImage={setImage} 
            setConfirmPic={confirmPicHandler} 
            camError={camError} 
            setCamError={setCamError}
            showLoader={showLoader}
        /> }
       
    </div>
 )
}
