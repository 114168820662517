import React, { useEffect, useState, useRef, useMemo } from 'react';
import { config } from '../config';
// import TagIcon from '../assets/img/tagIcon.svg';
import Barcode from '../assets/img/barcode.svg';
// import Cart from '../assets/img/cart.svg';
// import CartGreen from '../assets/img/cart-green.svg';
// import { UserService } from '../services/UserService';
import Product from './Product';
import Avatar from './Avatar';
import SingleSelect from "react-custom-single-select"; //https://www.npmjs.com/package/react-custom-single-select?activeTab=readme
import { BringmeService } from '../services/BringmeService';
import { BringmeMsg } from './BringmeMsg';
import { ColorCmp } from './ColorCmp';
import { dateAdd, getUniqueBy, moveInArray } from '../helpers/helpers';
import TryOn from './TryOn';
import ProgressiveImage from "react-progressive-graceful-image";
// import RedShoppingBag from '../assets/img/red-shopping-bag.svg'
import { SliderMsg } from './checkOut/sliderMsg';
import { blackV, tagIcon } from '../assets/img/staticImages';

// import { useCallback } from 'react';

// import useFitText from "use-fit-text";

const selectSizeHandler = (sizes, recommended_size, variantSize) => {
    let sizesArray = sizes.map(s => s.size) || [];
    if (sizesArray.includes(recommended_size)) return recommended_size;

    for (const size of sizesArray) {
        const splitted = size.split('/');
        if (splitted.length < 2) continue;
        else if (splitted[0] === recommended_size) {
            return size;
        }
    }
    return variantSize;
}

export const Step3 = ({ user, variant, setVariant, setScannedItem, mirrorData, product, showAvatar, setShowAvatar, shoppingBag, setShoppingBag, setScannerVisible }) => {
    console.log('variant:', variant, 'product:', product, "user:", user);
    // const token = UserService.parseJwt(user.token);
    // const provider = token?.firebase?.sign_in_provider;
    // const name = [undefined, 'anonymous'].includes(provider) ? 'Guest' : user.full_name;
    let timer;
    const price = Number(product?.price).toFixed(2);
    const [stock, setStock] = useState(variant.stock || 0);
    const [sizePicked, setSizePicked] = useState(selectSizeHandler(product?.size, product?.recommended_size, variant.size))
    const [isSelectOpened, setIsSelectOpened] = useState(false)
    const [showBringMeMsg, setShowBringMeMsg] = useState(false)
    const [isVariantAvailable, setIsVariantAvailable] = useState();
    const [showTryOn, setShowTryOn] = useState(false);
    const [tryOnImage, setTryOnImage] = useState("");
    const [itemAdded, setItemAdded] = useState({ msg: '', isShow: false });


    const sizePickerRef = useRef();
    const currBarcode = useRef(variant.barcode);

    // const { fontSize, ref } = useFitText();

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        document.documentElement.style.height = document.documentElement.scrollHeight + 'px';
    });

    useEffect(() => {
        // variant:
        // {
        //     "id": 439,
        //     "barcode": "1234567890028",
        //     "image_url": null,
        //     "avatar": "male",
        //     "size": "S",
        //     "color": "white",
        //     "stock": 9
        // }
        // 

        console.log('size picked:', sizePicked, 'color:', variant.color, 'variant available:', verifyVariantAvailable(sizePicked, variant.color));
        setIsVariantAvailable(verifyVariantAvailable(sizePicked, variant.color))
        verifyVariantAvailable(sizePicked, variant.color) ? sizePickerRef?.current?.classList.remove('disabled') : sizePickerRef?.current?.classList.add('disabled')
        setStock(product.variants.find(v => {
            return (v.size === sizePicked && v.color === variant.color)
        })?.stock || 0)
        currBarcode.current = product.variants.find(v => {
            return (v.size === sizePicked && v.color === variant.color)
        })?.barcode || null;
    }, [sizePicked, variant])

    const verifyVariantAvailable = (sizePicked, colorPicked) => {
        const userVariant = product.variants.find(currVariant => currVariant.size === sizePicked && currVariant.color === colorPicked)
        return userVariant && (('stock' in userVariant && userVariant.stock > 0) || !('stock' in userVariant))
    }
    // const registerItem = () => {

    //     window.registerItem({
    //         "itemID": variant.id,
    //         "unitOfMeasureCode": "PCE",
    //         "itemType": "CO",
    //         "actualUnitPrice": variant.price,
    //         "quantity": 1,
    //         "receiptText": `${variant.product.name}-${sizePicked}`.substring(0, 38),
    //         "registrationNumber": variant.id,
    //         "taxGroupID": "A1"
    //     });
    // }


    // product.variants.map(variant=><div data-barcode={variant.barcode} data-color={variant.color}>{variant.size}{variant.stock===0?' (not in stock)':''}</div>)

    const Row = (size) => {
        return (
            <div
                style={{
                    minHeight: '38px',
                    maxHeight: '38px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingInline: '10px',
                }}
                className={`${verifyVariantAvailable(size.size, variant.color) ? '' : 'disabled'}`}
            >
                <div>{size.size}</div>
            </div>
        );
    };

    useEffect(() => {
        sizePickerRef?.current?.querySelectorAll('.disabled').forEach(el => el.parentElement.classList.add('disabled'))
        verifyVariantAvailable(sizePicked, variant.color) ? sizePickerRef?.current?.classList.remove('disabled') : sizePickerRef?.current?.classList.add('disabled')
    }, [isSelectOpened])

    const completingProducts = useMemo(() => {
        return product.completing_products?.filter(product => product.main_variant).map((productItem, index) => <React.Fragment>
            <Product product={productItem} user={user} setScannedItem={setScannedItem} currency={product?.currency.currency} key={productItem.id + '-' + index + Date.now()} />
            {product.completing_products?.length !== index + 1 && <div className="product-seperator"></div>}
        </React.Fragment>
        )
    }, [variant])

    const similarProducts = useMemo(() => {
        return product.similar_products?.filter(product => product.main_variant).map((productItem, index) => <React.Fragment>
            <Product product={productItem} user={user} setScannedItem={setScannedItem} currency={product?.currency.currency} key={productItem.id + '-' + index + Date.now()} />
            {product.similar_products?.length !== index + 1 && <div className="product-seperator"></div>}
        </React.Fragment>
        )
    }, [variant])

    const similarItems = useMemo(() => <div className="products-container products-container--similar">
        <div className="products-container__title">Similar items</div>
        <div className="products-container__carousel">{similarProducts}</div>
    </div>, [similarProducts])

    const completingItems = useMemo(() => <div className="products-container">
        <div className="products-container__title">Goes with</div>
        <div className="products-container__carousel completing">{completingProducts}</div>
    </div>, [completingProducts])


    const variantImg = useMemo(() => {
        let img = 'image_url' in variant && variant.image_url !== 'no_garment.svg' ? variant.image_url : tagIcon;
        img = tryOnImage || img;
        // return <img className={`item-img ${tryOnImage ? 'item-img--try-on' : ""}`} src={img} alt="Item" />
        return img
    }, [variant, tryOnImage])

    // console.log("stock:", stock);

    const onBringMe = () => {
        if (!isVariantAvailable) return

        const selectedVariant = product.variants.find(currVariant => currVariant.size === sizePicked && currVariant.color === variant.color)
        BringmeService.send({ notificationCode: 100, barcode: selectedVariant.barcode, stationName: mirrorData.mirrorName, retailerCode: mirrorData.retailerCode })
        setShowBringMeMsg(true)
        clearTimeout(timer)
        timer = setTimeout(() => setShowBringMeMsg(false), 3000)
    }

    const parseColors = () => {
        if (product.code > 200) {
            return [{ color: variant.color, barcode: variant.barcode }]
        }
        // console.log(product.variants.map(variant => variant.color));
        return getUniqueBy(product.variants, 'color').map(p => { return { color: p.color, barcode: p.originalObject.barcode } })
    }

    const onColorClick = ({ target }) => {
        const barcode = target.dataset.barcode.toString()
        verifyVariantAvailable(sizePicked, product.variants.find(v => v.barcode === barcode)?.color) ? sizePickerRef?.current.classList.remove('disabled') : sizePickerRef?.current.classList.add('disabled')
        try {
            const allBarcodes = [...new Set(product?.variants?.map((variant, idx) => {
                // variant=> {avatar: "male", barcode: "1234567890028", color: "white", id: 439, image_url: null, 
                // size: "S", stock: 9}
                return variant.barcode;
            }))]

            // console.log('variant barcode',variant.barcode, 'barcode', barcode, 'variant barcode===barcode', variant.barcode === barcode);
            // console.log(allBarcodes, barcode, allBarcodes.includes(barcode));
            if (new Date() > dateAdd(new Date(product.updatedAt), 'minute', 5)) {
                window.SCAN(barcode)
                return
            } else if (allBarcodes.includes(barcode)) {
                const variant = product.variants.find(v => v.barcode === barcode)
                // setSizePicked(selectSizeHandler(product?.size, product?.recommended_size, variant.size))
                setVariant(variant)
            }
        } catch (err) {
            window.SCAN(barcode)
        }
    }


    const tryOnHandler = () => {
        setShowTryOn(true);
    }



    return (
        <section className="step3">
            <SliderMsg img={variantImg} icon={blackV} msg={itemAdded.msg} timer={itemAdded.timer} isShow={itemAdded.isShow} timerCB={() => setItemAdded(false)} />
            {showAvatar && <Avatar gender={variant.avatar} bodyType={user.bodyType} recommendedSize={product.recommended_size} setShowAvatar={setShowAvatar} />}
            <div className="top">
                <ProgressiveImage delay={config.imageLoaderDelay} src={variantImg} placeholder=''>
                    {(src, loading) => {
                        return loading ? <div className={`item-img shimmer-loader`} /> :
                            <img className={`item-img ${tryOnImage ? 'item-img--try-on' : ""} fade-in`} src={src} alt="Item" />
                    }
                    }
                </ProgressiveImage>
                {/* {variantImg} */}
                {/* {product.image_url && <img className="item-img item-img2" src={product.image_url==='no_garment.svg'?TagIcon:product.image_url} alt="Item" ref={ref}/>} */}
                {!config.isScannerVisible && parseColors().length > 1 && <ColorCmp colors={parseColors()} onClick={onColorClick} value={variant.color} />}
            </div>
            <div className="product-info">
                <div className="product-info__name">{product.name}</div>
                {'stock' in variant && <div className={(stock > 3 && verifyVariantAvailable(sizePicked, variant.color)) ? 'product-info__stock in-stock' : 'product-info__stock out-stock'}>{(stock > 3 && verifyVariantAvailable(sizePicked, variant.color)) ? 'In stock' : (stock === 0 || !verifyVariantAvailable(sizePicked, variant.color)) ? 'Out of stock' : `Only ${stock} left in stock`}</div>}
                <div className="product-info__price">{product?.currency.currency}{price}</div>


                {/* {product.recommended_size && product.avatar && user.bodyType > 0 &&  <div className="product-info__3d-btn" onClick={() => setShowAvatar(prev => !prev)}>View this item in 3D!</div>} */}


                <div className="product-info__size product-info__button">

                    <div>{product.recommended_size ? `${"Your size is: " + product.recommended_size}` : "No matching size"}</div>
                </div>
                {!config.isScannerVisible && <div
                    className={`product-info__size-picker product-info__button ${isSelectOpened ? 'opened' : ''}`}
                    onClick={() => {
                        setIsSelectOpened(!isSelectOpened);
                    }}
                    ref={sizePickerRef}
                >
                    <SingleSelect
                        // options={product.size}
                        options={product.size.map(s => Row(s))}
                        customStyle={{
                            Item: {
                                'justify-content': 'center',
                                'min-height': '3rem!important',
                                'max-height': '3rem!important',
                                // backgroundColor: 'white',
                            },
                            Header: {
                                'justify-content': 'center'
                            },
                            List: {
                                'overflow': 'auto',
                                backgroundColor: 'white',
                                border: 'none',
                                'z-index': '999'
                            }
                        }}
                        onSelect={(option, { index }) => setSizePicked(option.props.children.props.children)}
                        placeholder={sizePicked}
                    />
                </div>}
                {/* {console.log(user, product)} */}
                {user.bodyType > 0 &&
                    product?.recommended_size &&
                    variant.avatar &&
                    variant.avatar !== "zyler" &&
                    <div className="product-info__3d-btn product-info__button" onClick={() => setShowAvatar(true)} data-analytics="STEP3-view_3D">View this item in 3D!</div>
                }
                {variant.avatar === "zyler" &&
                    <div className="product-info__3d-btn product-info__button" onClick={tryOnHandler} data-analytics="STEP3-try_on">Try on</div>

                }
                {/* <div id="popup" className="product-info__add-to-basket product-info__button" onClick={registerItem}>
                    <div>Add To Bag</div>
                </div> */}
                {!config.isScannerVisible && mirrorData.isDemo && <div className={`product-info__add-to-basket product-info__button ${isVariantAvailable ? 'valid' : 'invalid'}`}
                    onClick={() => {
                        if (isVariantAvailable) {
                            const existItemIdx = shoppingBag.findIndex(item => item.barcode === currBarcode.current)
                            const currShoppingBag = [...shoppingBag]
                            if (existItemIdx < 0) { //new item in bag
                                currShoppingBag.push({ barcode: currBarcode.current, name: product.name, quantity: 1, color: variant.color, size: sizePicked, img_url: variantImg, currency: product.currency.currency, price: price })
                            } else { //item exist - add to quantity
                                currShoppingBag[existItemIdx].quantity++
                            }
                            setShoppingBag(currShoppingBag);
                            setItemAdded({ msg: 'Added to bag', timer: 2000, isShow: true })
                        }
                    }}
                    data-analytics="STEP3-add_to_bag"
                >
                    {/* <img className="cart-icon" src={isCartFull?.items?.has(variant.barcode) ? CartGreen : Cart} alt="cart" /> */}
                    <div>Add to bag</div>
                </div>}
                {!config.isScannerVisible && <div id="popup" className={`product-info__add-to-basket product-info__button ${isVariantAvailable ? 'valid' : 'invalid'}`} onClick={isVariantAvailable ? onBringMe : null} data-analytics="STEP3-bring_me">
                    <div>Bring me this item</div>
                </div>}
                {/* <div className="product-info__try-on product-info__button" onClick={tryOnHandler}>Try On </div> */}
                {!config.isScannerVisible && <div className="product-info__scan product-info__button">
                    <img src={Barcode} alt="barcode" />
                    <p>Or scan another item</p>
                </div>}
                { config.isScannerVisible &&
                    <div className="product-info__button product-info__add-to-basket"  onClick={()=>setScannerVisible(true)} >Scan another item</div>

                }
            </div>
            {!config.isScannerVisible && similarProducts?.length > 0 && similarItems}
            {!config.isScannerVisible && completingProducts?.length > 0 && completingItems}
            {isSelectOpened &&
                <div className="size-screen" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '10' }} onClick={() => {
                    document.querySelector('.product-info__size-picker.product-info__button>div>div').click()
                    setIsSelectOpened(false)
                }}></div>}

            <BringmeMsg isShow={showBringMeMsg} />
            {showTryOn &&
                <div className="step3__tryon">
                    <TryOn user={user} setTryOnImage={setTryOnImage} showTryOnHandler={setShowTryOn} />
                </div>
            }
        </section>
    )
}

