import { config } from "../config";
import { HttpService } from "./HttpService";

export const BringmeService = {
    send
}

const BASE_URL = config[process.env.REACT_APP_ENV_NAME].PRODUCT_URL;

function send({notificationCode, barcode, stationName, retailerCode}) {
    HttpService.post(`${BASE_URL}item_requests`, {notification_code: notificationCode, barcode: barcode, station_name: stationName, retailer_code: retailerCode})
}