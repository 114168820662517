import { useEffect, useRef, useState } from "react"
import { ItemCmp } from "./itemCmp"

export const ItemList = ({ itemList, removeItem }) => {
    const [total, setTotal] = useState({ amount: 0, currency: '' })
    const currencySign = useRef((itemList.length && itemList[0].currency) || null)

    useEffect(() => {
        calcTotal()
    }, [itemList])

    const calcTotal = () => {
        let total = 0
        !currencySign.current && (currencySign.current = total.currency)
        if (itemList.length > 0) {
            itemList.forEach(item => {
                total = +total + (+item.price * +item.quantity)
            })
            setTotal({ amount: total.toFixed(2), currency: itemList[0].currency })
        } else setTotal({ amount: 0, currency: currencySign.current })
    }

    return (
        <div className='item-list'>
            {itemList.length ? 
                itemList.map(item => <ItemCmp key={`${item.barcode}-${item.color}-${item.size}`} item={item} removeItem={removeItem} />)
                : <div className='no-items'>Your Bag Is Empty</div>
            }
            {itemList.length>0 && <div className='order-total'>
                    <label className='title'>Order Total:</label>
                    <label className='amount'><span>{total.currency}</span><span>{total.amount}</span></label>
                </div>}
        </div>
    )
}