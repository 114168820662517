import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { clearFLSMstate } from './services/GtagService';
import { config } from './config';
// import 'bootstrap/dist/css/bootstrap.css'; //for slider
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'; //for slider

const container = document.getElementById('root');
const root = createRoot(container);

if ('fonts' in document) {
    Promise.all([
        document.fonts.load('1em Monserrat'),
        document.fonts.load('1em Monserrat-medium'),
        document.fonts.load('1em Monserrat-bold'),
        document.fonts.load('1em Monserrat-semi-bold')
    ]).then(function _(){
        document.documentElement.classList.add('fonts-loaded')
    })
}

clearFLSMstate()
root.render(<React.StrictMode>
    <App />
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
